import * as React from "react";
import "./pool-clientlist-model.css";
import Modal from "@mui/material/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";

const tableContainer = {
  width: "98%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  width: `${100 / 3}%`,
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 15,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "8px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "8px",
};

export default function PoolClientlistModel(props) {
  return (
    <Modal
      open={props.openClientModel}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        overflow: "scroll",
      }}
    >
      <div className="pool_clientlist_model_div">
        {/* title */}
        <div className="pool_clientlist_model_title_div">
          <h1 className="pool_clientlist_model_title">Select Client</h1>
        </div>

        {/* search_bar */}
        <div className="pool_clientlist_search_bar_div">
          <div className="pool_clientlist_search_bar">
            {/* icon */}
            <SearchIcon
              style={{
                fontSize: "20px",
                paddingRight: "10px",
                color: "#555555",
              }}
            />
            {/* input box */}
            <input
              type="text"
              placeholder="Search by Name"
              className="pool_clientlist_search_bar_input"
              onChange={props.requestSearch}
            />
          </div>
        </div>

        {/* table container */}
        <div className="pool_clientlist_table_div">
          {/* table */}
          <TableContainer style={tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={tableHeader}>
                    Name
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Mobile No.
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      ...tableHeader,
                      borderRight: "none",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.clientData
                  .sort(props.sortByName)
                  .slice(
                    props.page * props.rowsPerPage,
                    props.page * props.rowsPerPage + props.rowsPerPage
                  )
                  .map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                      }}
                    >
                      <TableCell style={tableRow} align="left">
                        {row.name}
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {row.mobile}
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableRow,
                          borderRight: "none",
                        }}
                        align="left"
                      >
                        <button
                          className="pool_clientlist_action_button_invest"
                          onClick={() => props.invest(row)}
                        >
                          {row.action}
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {/* if no data available */}
            {props.clientData.length === 0 ? (
              <div className="no_row_found_div">
                <FindInPageIcon
                  style={{
                    fontSize: "100px",
                    color: "#555555",
                  }}
                />
                <p className="no_row_found">No row found</p>
              </div>
            ) : (
              <div />
            )}
          </TableContainer>
          {/* pagination */}
          <div className="pagination_div" style={{ width: "98%" }}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={props.clientData.length}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onPageChange={props.handleChangePage}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
