import React, { useEffect, useState } from "react";
import "./overview.css";
import { Auth } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { Redirect,useHistory } from "react-router-dom";
import { User_API } from "../../services/api";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  errorToast,
  getFormattedDate,
  convertAmount,
  convertIntoINR,
} from "../../utils/helper";
import {
  ContentLoadingLoader,
  CustomTooltip,
  FullScreenLoader,
  SampleToast,
} from "../../components";
import { useStores } from "../../models";

const tableContainer = {
  marginTop: "20px",
  width: "95%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 15,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 13,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const Overview = observer(function Overview(props) {
  const navigate = useHistory();
  const [isLogin, setIsLogin] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const pageSize = 4;
  const [pagination, setPagination] = useState({});
  const [aumData, setAumData] = useState([]);
  const [fullLoader, setFullLoader] = useState(false);
  const [loginRender, setLoginRender] = useState(false);
  const [render, setRender] = useState(false);
  const api = new User_API();
  const rootStore = useStores();

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        fetchOverview();
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // get the data
  const fetchOverview = () => {
    rootStore.overview.getOverview().then((res) => {
      console.log("Response", res);
      if (res.kind === "ok") {
        setPartnerData(res.data);
        setRender(true);
      } else {
        setRender(true);
        errorToast(res.error);
      }
    });
  };

  // close model
  const handleClose = () => {
    setOpenModel(false);
  };

  // fetch aum data
  const fetchAumData = () => {
    setFullLoader(true);
    getAumData(pageSize, 1);
  };

  // get aum data
  const getAumData = async (pageSize, page) => {
    await api.getUserAumDetails(pageSize, page).then((res) => {
      if (res.kind === "ok") {
        setFullLoader(false);
        setPagination(res.meta.pagination);
        setAumData(res.data);
        setOpenModel(true);
      } else {
        setFullLoader(false);
        errorToast(res.error);
      }
    });
  };

  const TooltipOverviewLabel = ({ label, infoText }) => {
    return (
      <div className="overview_tooltip_grid">
        <p className="overview_row_container_title">{label}</p>

        <div>
          <CustomTooltip info={infoText}>
            <InfoIcon
              style={{
                fontSize: "12px",
                marginLeft: "1px",
                color: "#3A86FF",
                // border:"1px solid yellow"
              }}
            />
          </CustomTooltip>
        </div>
      </div>
    );
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="overview_div_container">
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="overview_rest_div">
              {/* heading */}
              {/*<h1 className="overview_rest_div_heading">Overview</h1>*/}

              {/* column container 1 */}
              <div className="overview_border">
                <div className="overview_client_background">
                  <h1 className="overview_client_heading">Summary</h1>
                </div>
                <div className="overview_column_container">
                  {/* total number of clients */}
                  <div className="overview_row_container">
                    <div className="overview_row_container_box">
                      <p className="overview_row_container_title">
                        Number of investors
                      </p>
                      <p className="overview_row_container_subtitle">
                        {partnerData !== undefined
                          ? partnerData.total_clients
                          : "0"}
                      </p>
                    </div>
                  </div>

                  {/* total AUM */}
                  <div className="overview_row_container">
                    <div className="overview_row_container_box">
                      <TooltipOverviewLabel
                        label="Commissions"
                        infoText="Total Commissions accrued by partner on investments till date."
                      />
                      <p className="overview_row_container_subtitle">
                        ₹
                        {partnerData !== undefined
                          ? convertIntoINR(partnerData.total_commission_accrued)
                          : "0"}
                      </p>
                      <p
                        className="overview_row_container_highlight"
                        onClick={()=>{
                          navigate.push('/commission')
                        }}
                        style={{
                          display: partnerData
                              ? partnerData.total_commission_accrued
                                ? "flex"
                                : "none"
                              : "none"
                          // display : "none"
                        }}
                      >
                        View Breakdown
                      </p>
                    </div>
                  </div>

                  {/* total AUM */}
                  <div className="overview_row_container">
                    <div className="overview_row_container_box">
                      <TooltipOverviewLabel
                        label="Commission Rate"
                        infoText="Latest Referral Fee associated with NCD."
                      />
                      <p className="overview_row_container_subtitle">
                        {partnerData !== undefined
                          ? partnerData.referral_fee
                          : "0"}%
                      </p>
                    </div>
                  </div>

                  {/* total in-process amount */}
                  {/* <div className="overview_row_container">
                <div className="overview_row_container_box">
                  <TooltipOverviewLabel
                    label="In-process amount"
                    infoText="Value of committed investments that are getting deployed to borrowers; usually takes 72 hours from the time of fund transfer to get deployed"
                  />
                  <p className="overview_row_container_subtitle">
                    {partnerData !== undefined
                      ? partnerData.summary !== undefined
                        ? partnerData.summary.in_process_amount
                          ? `₹ ${convertAmount(
                              partnerData.summary.in_process_amount.toFixed(2)
                            )}`
                          : "₹ 0"
                        : "₹ 0"
                      : "₹ 0"}
                  </p>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          )}

          {/* full screen loader */}
          {fullLoader ? (
            <FullScreenLoader open={fullLoader ? true : false} />
          ) : (
            <div />
          )}

          {/* toast container */}
          <SampleToast />
        </div>
      )}

      {/* show model */}
      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflow: "scroll",
        }}
      >
        <div className="overview-model-div">
          {/* header */}
          <div className="overview-model-header-div">
            <h1 className="overview-model-header-text">
              Breakdown of AUM Changes
            </h1>
          </div>
          {/* table content */}
          <TableContainer style={tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={tableHeader}>
                    Date
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Client Name
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Pool Issue Name
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Investments / Repayments
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      ...tableHeader,
                      borderRight: "none",
                    }}
                  >
                    AUM
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aumData.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                    }}
                  >
                    <TableCell style={tableRow} align="left">
                      {getFormattedDate(row.date)}
                    </TableCell>
                    <TableCell style={tableRow} align="left">
                      {row.clientName}
                    </TableCell>
                    <TableCell style={tableRow} align="left">
                      {row.poolName}
                    </TableCell>
                    <TableCell style={tableRow} align="left">
                      {row.changeBy}
                    </TableCell>
                    <TableCell
                      style={{
                        ...tableRow,
                        borderRight: "none",
                      }}
                      align="left"
                    >
                      {convertAmount(row.amount.toFixed(2))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* if no data available */}
            {aumData.length === 0 ? (
              <div className="no_row_found_div">
                <FindInPageIcon
                  style={{
                    fontSize: "100px",
                    color: "#555555",
                  }}
                />
                <p className="no_row_found">No row found</p>
              </div>
            ) : (
              <div />
            )}
          </TableContainer>
          {/* pagination */}
          <div className="overview_pagination_div">
            {/* previous */}
            <ArrowBackIosIcon
              style={{
                fontSize: "14px",
                color: pagination.current_page === 1 ? "#b8b8b8" : "#3d3d3d",
                marginRight: "20px",
                cursor: pagination.current_page === 1 ? "default" : "pointer",
              }}
              onClick={() => {
                if (pagination.current_page !== 1) {
                  getAumData(pageSize, pagination.current_page - 1);
                }
              }}
            />
            {/* next */}
            <ArrowForwardIosIcon
              style={{
                fontSize: "14px",
                color: pagination.count === pageSize ? "#3d3d3d" : "#b8b8b8",
                marginRight: "30px",
                cursor: pagination.count === pageSize ? "pointer" : "default",
              }}
              onClick={() => {
                if (pagination.count === pageSize) {
                  getAumData(pageSize, pagination.current_page + 1);
                }
              }}
            />
          </div>
          {/* footer */}
          <div className="overview-model-footer-div">
            <button
              className="overview-model-footer-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default Overview;
