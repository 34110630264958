import React, { useEffect, useState } from "react";
import "./commission.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { useStores } from "../../models";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth, Storage } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {
  errorToast,
  convertAmount,
  successToast,
  convert_json_array_to_csv,
  convertIntoINR,
} from "../../utils/helper";
import {
  ContentLoadingLoader,
  FullScreenLoader,
  SampleToast,
} from "../../components";
import awsConfig from "../../constant/aws-exports";
import CommissionInvestment from "./commission_investment/commission_investment";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  marginLeft: "1%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
  height: "auto",
  minWidth: "900px",
  overflowY: "scroll",
};

const tableHeader = (e) => {
  return {
    backgroundColor: "#ececec",
    fontWeight: "600",
    fontSize: 16,
    fontFamily: "Asap",
    color: "#1f1f1f",
    borderRight: "1px solid #c7c7c7",
    paddingTop: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "15px",
    width: e ? "200%" : "inherit",
  };
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingBottom: "10px",
};

const Commission = observer(function Commissions(props) {
  const navigate = useHistory();
  const [commissionData, setCommissionData] = useState([]);
  const [page, setPage] = useState(0);
  const [pan_number, setPanNumber] = useState("");
  const [totalCommissions, setTotalCommissions] = useState(0);
  const rowsPerPage = 10;
  const [render, setRender] = useState(true);
  const rootStore = useStores();
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [selectedNcd, setSelectedNcd] = useState();
  const [fullLoader, setFullLoader] = useState(false);
  const [openInvestmentCommission,setOpenInvestmentCommission]= useState(false);
  const [ncdRowSelected,setNcdRowSelected] = useState();

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        setRender(true);
        fetchCommission(null, page, rowsPerPage);
      } catch (err) {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {}, [commissionData]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  // fetch the client list
  const fetchCommission = (
    ncd = null,
    page = null,
    pagesize = null,
    isDownload = null
  ) => {
    setFullLoader(true);
    rootStore.commission
      .getCommission(ncd, page, pagesize, isDownload)
      .then((res) => {
        if (res.kind === "ok") {
          if (isDownload == true) {
            // Iterate over the commissions array and add serial numbers
            let temp_data = [...res.data];

            const data = convert_json_array_to_csv(temp_data, ["uuid","ncd_id"], {
              series_name: "Series Name",
              commission_rate: "Commission Rate (%)",
              commission_accrued: "Commission Accrued (₹}",
            });
            downloadFile({
              data: data,
              fileName: "Commission.csv",
              fileType: "text/csv",
            });
            setFullLoader(false);
          } else {
            setCommissionData(rootStore.commission.commission);
            setTotalCommissions(res.total);
            setRender(true);
            setDropDownData(res.ncd_series);
            setFullLoader(false);
          }
        } else {
          setRender(true);
          errorToast(res.error);
          setFullLoader(false);
        }
      });
  };

  console.log("commission data", commissionData);

  const handleDownloadExcel = (event) => {
    setPage(0);
    fetchCommission(selectedNcd, page, rowsPerPage, true);
  };
  // request search function
  const requestSearch = (event) => {
    setPage(0);
    setSelectedNcd(event.target.value);
    fetchCommission(event.target.value, 0, rowsPerPage, isDownload);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchCommission(selectedNcd, newPage, rowsPerPage, isDownload);
  };

  // sort list via name
  const sortByName = (a, b) => {
    if (String(a.name).trim() < String(b.name).trim()) {
      return -1;
    }
    if (String(a.name).trim() > String(b.name).trim()) {
      return 1;
    }
    return 0;
  };

  // request dropdown function
  const requestDropDown = (event) => {
    setPage(0);
    setSelectedNcd(event.target.value);
    fetchCommission(event.target.value, 0, rowsPerPage, isDownload);
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="commission_div_container">
          {/* table */}
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="commission_div_tablecontainer">
                {/* header */}
                <div className="commission_header_div">
                  {/* text */}
                  <h1 className="commission_header_div_title">Commissions</h1>
                </div>

                {/* search_bar */}
                <div className="commission_search_bar_div">
                  {dropDownData && dropDownData.length > 0 ? (
                    <>
                      <div className="investmentHistory_dropdown">
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 120, width: "200px" }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Tranche and Series
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedNcd}
                            label="Tranche and Series"
                            onChange={requestDropDown}
                          >
                            <MenuItem value="" key="-1">
                              Select Series
                            </MenuItem>
                            {dropDownData &&
                              dropDownData.length > 0 &&
                              dropDownData.map((item, index) => (
                                <MenuItem value={item.value} key={index}>
                                  {item.key}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="commission_download">
                        <Button
                          variant="contained"
                          onClick={handleDownloadExcel}
                          sx={{ width: "200px" }}
                        >
                          Download Excel
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>

                {/* table */}
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={tableHeader(commissionData[0]?.ncd_series)}
                        >
                          NCD Series
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(commissionData[0]?.ncd_series)}
                        >
                          Commission Rate
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(commissionData[0]?.ncd_series)}
                        >
                          Commission Accrued
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...tableHeader(commissionData[0]?.ncd_series),
                            borderRight: "none",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commissionData
                        .slice()
                        // .slice(
                        //   page * rowsPerPage,
                        //   page * rowsPerPage + rowsPerPage
                        // )
                        .map((row, index) => (
                          <TableRow
                            key={Math.random()}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#fff",
                            }}
                          >
                            <TableCell style={tableRow} align="center">
                              <div >
                                {row.series_name}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div >
                                {row.commission_rate}%
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div>
                                ₹{convertIntoINR(row.commission_accrued)}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                ...tableRow,
                                borderRight: "none",
                              }}
                              align="center"
                            >
                              <div className="commission-action-button-div">
                                <button
                                  className="commission-action-button-div-button"
                                  onClick={() =>{
                                    setOpenInvestmentCommission(true)
                                    setNcdRowSelected(row.ncd_id)
                                  }}
                                >
                                  Breakdown
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {totalCommissions === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">No row found</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>
                {/* pagination */}
                <div className="pagination_div">
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalCommissions}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            )}
          </div>
          {fullLoader ? (
            <FullScreenLoader open={fullLoader ? true : false} />
          ) : (
            <div />
          )}
          {/* toast container */}
          <SampleToast />
        </div>
      )}
      <CommissionInvestment open={openInvestmentCommission} handleClose={()=>setOpenInvestmentCommission(false)} ncd_id={ncdRowSelected} />
    </div>
  );
});

export default Commission;
