import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const ProfileModel = types
  .model("ProfileModel")
  .props({
    account_name: types.optional(types.string, ""),
    account_number: types.optional(types.string, ""),
    bankDetailBranchName: types.optional(types.string, ""),
    bankDetailName: types.optional(types.string, ""),
    bank_id: types.optional(types.string, ""),
    client_id: types.optional(types.string, ""),
    due_date: types.maybeNull(types.string),
    email: types.optional(types.string, ""),
    face_value: types.optional(types.number, 0),
    ifsc_code: types.optional(types.string, ""),
    interest: types.optional(types.number, 0),
    investmentId: types.optional(types.number, 0),
    investmentTransactionId: types.optional(types.string, ""),
    investmentUUID: types.optional(types.string, ""),
    investment_amount: types.optional(types.number, 0),
    investment_start: types.optional(types.string, ""),
    mode_of_investment: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    ncd_id: types.optional(types.string, ""),
    pan_number: types.optional(types.string, ""),
    payout_date: types.maybeNull(types.string),
    phone: types.optional(types.string, ""),
    principal: types.optional(types.number, 0),
    repaymentId: types.optional(types.number, 0),
    repaymentTransactionId: types.maybeNull(types.string),
    repaymentUUID: types.optional(types.string, ""),
    repayment_tds_rate: types.optional(types.number, 0),
    series_name: types.optional(types.string, ""),
    status: types.optional(types.number, 0),
    total_number_of_ncd: types.optional(types.number, 0),
    type: types.maybeNull(types.string)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type ProfileModelDataType = Instance<typeof ProfileModel>;
export interface Profile extends ProfileModelDataType { }

type ProfileModelSnapshotType = SnapshotOut<typeof ProfileModel>;
export interface ProfileSnapshot extends ProfileModelSnapshotType { }
