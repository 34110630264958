import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PastRepayment from "./pastRepayment";
import UpComingPayment from "./upcomingRepayment";
import { useStores } from "../../models";
import { useHistory, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { errorToast } from "../../utils/helper";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  // check if user login or not
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Repayment = observer(function Investmenthistory(props) {
  const [value, setValue] = useState(0);
  const navigate = useHistory();
  const [render, setRender] = useState(true);
  const rootStore = useStores();
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        const type = props?.location?.state?.type || null;
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        if (type === "upcoming") {
          a11yProps(1);
          setValue(1);
        }
      } catch (err) {
        console.log("repayment use effect catch", err);
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, [props]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ marginTop: "8%", marginLeft: "250px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Past Repayments" {...a11yProps(0)} />
            <Tab label="Upcoming Repayments" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PastRepayment value={props?.location?.state || null} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UpComingPayment value={props?.location?.state || null} />
        </TabPanel>
      </Box>
    </div>
  );
});

export default Repayment;
