import * as React from "react";
import "./common-header.css";
import WorkIcon from "@mui/icons-material/Work";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const CommonHeader = function () {
  const name = localStorage.getItem("name");
  const mobile = localStorage.getItem("mobile");

  return (
    <div className="common_header_main_container">
      {/* logo */}
      <img
        src="arthmate.png"
        alt="logo"
        className="common_header_logo"
        onClick={() => window.open("https://www.arthmate.com/", "_blank")}
      />

      {/* ifa details */}
      <div className="common_header_ifa_details">
        <div className="common_header_ifa_details_part">
          {/* suitcase icon */}
          <WorkIcon
            style={{
              fontSize: "18px",
              marginRight: "10px",
              color: "#42474F",
            }}
          />
          {/* name */}
          <p
            className="common_header_text"
            style={{
              marginRight: "15px",
            }}
          >
            {name}
          </p>
        </div>

        {/* divider */}
        <div className="common_header_divider" />

        <div className="common_header_ifa_details_part">
          {/* phone icon */}
          <LocalPhoneIcon
            style={{
              fontSize: "18px",
              marginRight: "10px",
              color: "#42474F",
            }}
          />

          {/* mobile */}
          <p className="common_header_text">{mobile}</p>
        </div>
      </div>
    </div>
  );
};

export default CommonHeader;
