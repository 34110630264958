import React from "react";
import "./index.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { CommonHeader, CommonSidebar } from "./components";
import {
  AuthPage,
  InvestmentHistory,
  Investor,
  Pagenotfound,
  Repayment,
  Profile,
  RepaymentDetail,
} from "./pages";
import routes from "./routes";

const beforeLogin = () => {
  return (
    <div>
      <p>hello</p>
    </div>
  );
};

const App = () => {
  const [showCommonHeader, setShowCommonHeader] = React.useState();
  const [showCommonSidebar, setShowCommonSidebar] = React.useState();

  // handle the sidebar and header
  const handleShowCommonHeader = (value) => {
    setShowCommonHeader(value);
  };

  // handle the sidebar and header
  const handleShowCommonSidebar = (value) => {
    setShowCommonSidebar(value);
  };

  return (
    <Router>
      <div className="route_container">
        {/* header */}
        {showCommonHeader && <CommonHeader />}

        <div className="route_rest_container">
          {/* side bar */}
          {showCommonSidebar && <CommonSidebar />}

          {/* routes */}
          <div className="route_switch_container">
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.id}
                  exact
                  path={route.path}
                  render={(props) => (
                    <route.component
                      {...props}
                      showCommonHeader={handleShowCommonHeader}
                      showCommonSidebar={handleShowCommonSidebar}
                    />
                  )}
                />
              ))}

              <Redirect to="/404" />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
