import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Investor,
  InvestorModel,
  InvestorSnapshot,
} from "../investor/investor";
import { Investor_API } from "../../../services/api";
import { isDebuggerStatement } from "typescript";

// make the instance of api
const api = new Investor_API();

export const InvestorStoreModel = types
  .model("InvestorStore")
  .props({
    investor: types.optional(types.array(InvestorModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param InvestorSnapshot
     */
    saveInvestor: (InvestorSnapshot: InvestorSnapshot[]) => {
      const investorModel: Investor[] = InvestorSnapshot.map((c) =>
      InvestorModel.create(c)
      );
      self.investor.replace(investorModel);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getInvestor: flow(function* (pan_number, page, pagesize, isDownload) {
      const result = yield api.getInvestor(pan_number, page, pagesize, isDownload);
      if (result.kind === "ok") {
        self.saveInvestor(result.investor);
        return { kind: "ok",data: result.investor, total: result.total || 0 };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type InvestorStoreType = Instance<typeof InvestorStoreModel>;
export interface InvestorStore extends InvestorStoreType {}

type InvestorStoreSnapshotType = SnapshotOut<typeof InvestorStoreModel>;
export interface InvestorStoreSnapshot extends InvestorStoreSnapshotType {}
