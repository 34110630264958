import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import { Auth } from "aws-amplify";
import Loader from "react-loader-spinner";
import { useStores } from "../../../models";
import InfoIcon from "@mui/icons-material/Info";
import { errorToast, successToast } from "../../../utils/helper";
import { SampleToast } from "../../../components";

// inputs fields component
const Field = React.forwardRef(
  (
    {
      label,
      type,
      handelChange,
      disabled,
      mobileInvalid,
      mobileInvalidText,
      otpInvalid,
      otpInvalidText,
      changeClick,
    },
    ref
  ) => {
    // prevent key press only numbers
    const onKeyPress = (e) => {
      const re = /[^0-9]/g;
      if (re.test(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <div
        className="login_field_container"
        style={{ marginTop: label === "Mobile number" ? 0 : "12px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <label className="login_label_container">{label}</label>
          <p
            onClick={changeClick}
            className="login_label_container"
            style={{
              display: label === "Mobile number" && disabled ? "flex" : "none",
              color: "#3a86ff",
              marginLeft: "5px",
              fontWeight: "500",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Change
          </p>
        </div>
        <input
          ref={ref}
          type={type}
          disabled={disabled}
          className="login_input_container"
          maxLength={label !== "OTP" ? 10 : 4}
          onChange={handelChange}
          onKeyPress={onKeyPress}
          style={{
            borderColor: mobileInvalid
              ? "#ff2e2e"
              : otpInvalid
              ? "#ff2e2e"
              : undefined,
          }}
        />

        {/* validation error */}
        <div className="login_invalid_div">
          {/* icon */}
          <InfoIcon
            style={{
              fontSize: "14px",
              paddingRight: "5px",
              color: "#ff2e2e",
              opacity: mobileInvalid ? 1 : otpInvalid ? 1 : 0,
            }}
          />
          {/* text */}
          <p
            className="login_invalid_text"
            style={{
              opacity: mobileInvalid ? 1 : otpInvalid ? 1 : 0,
            }}
          >
            {mobileInvalid
              ? mobileInvalidText
              : otpInvalid
              ? otpInvalidText
              : "Invalid"}
          </p>
        </div>
      </div>
    );
  }
);

// main login component
const Login = (props) => {
  const mobileRef = useRef();
  const otpRef = useRef();
  const [disable, setDisable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState(null);
  const [dbUser, setDatabaseUser] = useState(null);
  const [sendOtp, setSendOtp] = useState(false);
  const [mobileInvalid, setMobileInvalid] = useState(false);
  const [mobileInvalidText, setMobileInvalidText] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [otpInvalidText, setOtpInvalidText] = useState("");
  const navigate = useHistory();
  const rootStore = useStores();

  // prevent handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // hanlde input field changes
  const onhandelChange = (event) => {
    setMobileInvalid(false);
    setMobileInvalidText("");
    if (event.target.value.length === 10) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  // check mobile
  const checkMobile = () => {
    var number_format = /^[6-9][0-9]{9}$/;
    if (number_format.test(mobileRef.current.value)) {
      onSendOTP();
    } else {
      setMobileInvalid(true);
      setMobileInvalidText("Invalid mobile");
    }
  };

  // send the otp
  const onSendOTP = () => {
    console.log("onsendotp");
    setLoader(true);
    setDisable(true);
    const number = "+91" + mobileRef.current.value;
    rootStore.userData
      .checkUserExistence(mobileRef.current.value)
      .then(async (response) => {
        console.log(response);
        if (response.kind == "ok") {
          Auth.signIn(number)
            .then((res) => {
              setUser(res);
              setLoader(false);
              setDisable(false);
              setSendOtp(true);
              successToast("OTP has been sent successfully.", 1);
            })
            .catch((err) => {
              console.log(err);
              errorToast("Oops, something went wrong.");
              setTimeout(() => {
                setLoader(false);
                mobileRef.current.value = "";
                otpRef.current.value = "";
                setDisable(false);
                setSendOtp(false);
              }, 50);
            });
        } else {
          errorToast("This user does not exist!");
          setLoader(false);
          setDisable(true);
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        errorToast("This user does not exist!");
        setLoader(false);
        setDisable(true);
      });
  };

  // on login
  const onLogin = async () => {
    console.log("onlogin");
    if (otpRef.current.value.length === 0) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP cannot be empty");
    } else if (otpRef.current.value.length !== 4) {
      setOtpInvalid(true);
      setOtpInvalidText("Invalid OTP");
    } else if (isNaN(parseInt(otpRef.current.value))) {
      setOtpInvalid(true);
      setOtpInvalidText("OTP must be a number");
    } else {
      setDisable(true);
      setLoader(true);
      await Auth.sendCustomChallengeAnswer(user, otpRef.current.value)
        .then(async (res) => {
          console.log("res");
          console.log(res);
          if (res && !res.Session) {
            const curr_user = await Auth.currentUserCredentials();
            console.log("curr_user");
            console.log(curr_user);
            const data = {
              name: "",
              email: "",
              uuid: curr_user.identityId,
              mobile: mobileRef.current.value,
            };
            console.log("....................................");
            console.log(curr_user, res);
            rootStore.userData.updateUserUUID(data).then(async (res) => {
              if (res.kind === "ok") {
                rootStore.userData.getUserDetail().then(async (res) => {
                  if (res.kind === "ok") {
                    successToast("Successful login.");
                    setTimeout(() => {
                      navigate.push("/overview");
                    }, 2000);
                  } else {
                    await Auth.signOut()
                    .then(() => {
                      if (
                        res.error === "You are a new user please signup first"
                      ) {
                        errorToast(res.error);
                        setTimeout(() => {
                          setDisable(false);
                          setLoader(false);
                        }, 5000);
                      } else {
                        errorToast(res.error);
                        setTimeout(() => {
                          mobileRef.current.value = "";
                          otpRef.current.value = "";
                          setLoader(false);
                          setDisable(false);
                          setSendOtp(false);
                        }, 5000);
                      }
                    })
                    .catch((err) => {
                      errorToast("Oops, something went wrong.");
                      console.log(err);
                      setTimeout(() => {
                        mobileRef.current.value = "";
                        otpRef.current.value = "";
                        setLoader(false);
                        setDisable(false);
                        setSendOtp(false);
                      }, 5000);
                    });
                  }
                });
              } else {
                await Auth.signOut()
                  .then(() => {
                    if (
                      res.error === "You are a new user please signup first"
                    ) {
                      errorToast(res.error);
                      setTimeout(() => {
                        setDisable(false);
                        setLoader(false);
                      }, 5000);
                    } else {
                      errorToast(res.error);
                      setTimeout(() => {
                        mobileRef.current.value = "";
                        otpRef.current.value = "";
                        setLoader(false);
                        setDisable(false);
                        setSendOtp(false);
                      }, 5000);
                    }
                  })
                  .catch((err) => {
                    errorToast("Oops, something went wrong.");
                    console.log(err);
                    setTimeout(() => {
                      mobileRef.current.value = "";
                      otpRef.current.value = "";
                      setLoader(false);
                      setDisable(false);
                      setSendOtp(false);
                    }, 5000);
                  });
              }
            });
            
          } else {
            setOtpInvalid(true);
            setOtpInvalidText("Wrong OTP");
            setLoader(false);
            setDisable(false);
          }
        })
        .catch((err) => {
          errorToast("Oops, something went wrong.");
          console.log(err);
          setTimeout(() => {
            if(mobileRef && mobileRef.current) mobileRef.current.value = '';
            if(otpRef && otpRef.current) otpRef.current.value = '';
            setLoader(false);
            setDisable(false);
            setSendOtp(false);
          }, 5000);
        });
    }
  };

  const onChangeClick = async () => {
    await Auth.signOut()
      .then(() => {
        setUser(null);
        setDisable(false);
        setSendOtp(false);
      })
      .catch((err) => {
        errorToast("Oops, something went wrong.");
        console.log(err);
        setTimeout(() => {
          setUser(null);
          setLoader(false);
          setDisable(false);
          setSendOtp(false);
        }, 5000);
      });
  };

  return (
    <div className="main_login">
      {/* login container */}
      <div className="login_container">
        {/* header */}
        <header className="login_header">
          <h1 className="login_header_title">Login</h1>
        </header>

        {/* form */}
        <form className="login_form_container" onSubmit={handleSubmit}>
          {/* fields */}
          <Field
            ref={mobileRef}
            label="Mobile number"
            type="text"
            disabled={sendOtp}
            handelChange={onhandelChange}
            mobileInvalid={mobileInvalid}
            mobileInvalidText={mobileInvalidText}
            changeClick={onChangeClick}
          />
          {!sendOtp ? (
            <div />
          ) : (
            <Field
              ref={otpRef}
              label="OTP"
              type="text"
              disabled={!sendOtp}
              otpInvalid={otpInvalid}
              otpInvalidText={otpInvalidText}
              handelChange={() => {
                setOtpInvalid(false);
                setOtpInvalidText("");
              }}
            />
          )}

          {/* submit */}
          <div className="login_field_container">
            <button
              className="login_submit_container"
              style={{
                cursor: disable ? "not-allowed" : "pointer",
              }}
              onClick={sendOtp ? onLogin : checkMobile}
              disabled={disable}
            >
              {loader ? (
                <Loader type="TailSpin" color="#fff" height={15} width={15} />
              ) : sendOtp ? (
                "Login"
              ) : (
                "Send OTP"
              )}
            </button>
          </div>
        </form>
      </div>

      {/* toast container */}
      <SampleToast />
    </div>
  );
};

export default Login;
