import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const InvestmentHistoryModel = types
  .model("InvestmentHistoryModel")
  .props({
    uuid: types.optional(types.string, ""),
    partner_id: types.optional(types.string, ""),
    pan_number: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    series_name: types.optional(types.string, ""),
    fund_received_date: types.optional(types.string, "") || null,
    investment_start: types.optional(types.string, "") || null,
    mode_of_investment: types.optional(types.string, ""),
    investment_amount: types.optional(types.string, ""),
    tds_rate: types.optional(types.number, 0),
    account_name: types.optional(types.string, ""),
    account_number: types.optional(types.string, ""),
    ifsc_code: types.optional(types.string, ""),
    bank_name: types.optional(types.string, ""),
    branch_name: types.optional(types.string, ""),
    transaction_id: types.optional(types.string, ""),
    folio_number: types.optional(types.string,""),
    nominee_name: types.optional(types.string, ""),
    relationship_with_user: types.optional(types.string, ""),
    soa_letter: types.maybeNull(types.string),
    allotment_letter: types.maybeNull(types.string),
    has_allotment_sent: types.maybeNull(types.number),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type InvestmentHistoryModelDataType = Instance<typeof InvestmentHistoryModel>;
export interface InvestmentHistory extends InvestmentHistoryModelDataType {}

type InvestmentHistoryModelSnapshotType = SnapshotOut<typeof InvestmentHistoryModel>;
export interface InvestmentHistorySnapshot extends InvestmentHistoryModelSnapshotType {}
