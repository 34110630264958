import React, { useEffect, useState } from "react";
import "./investmentHistory.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import { useStores } from "../../models";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth, Storage } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { errorToast, convertAmount, successToast, convert_json_array_to_csv } from "../../utils/helper";
import { ContentLoadingLoader, SampleToast } from "../../components";
import awsConfig from "../../constant/aws-exports";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  marginLeft: "1%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
  height: "auto",
  minWidth: "900px",
  overflowY: "scroll",
};

const tableHeader = {
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingBottom: "15px"
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingBottom: "10px",
};

const InvestmentHistory = observer(function Investmenthistory(props) {
  const [tranche, setTranche] = useState("");
  const [dropDownData, setDropDownData] = useState([]);
  const [investmentHistoryData, setInvestmentHistoryData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [render, setRender] = useState(true);
  const rootStore = useStores();
  const navigate = useHistory();
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [pan_number, setPanNumber] = useState("");
  const [isDownload, setIsDownload] = useState(false);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        const pan_number = props?.location?.state?.pan_number || null;
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        setPanNumber(pan_number);
        fetchInvestmentHistory(pan_number, tranche, page, rowsPerPage);
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, [props]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  // fetch the client list
  const fetchInvestmentHistory = (
    pan_number = null,
    series_name = null,
    page = null,
    pagesize = null,
    isDownload = null
  ) => {
    rootStore.investmentHistory
      .getInvestmentHistory(pan_number, series_name, page, pagesize, isDownload)
      .then((res) => {
        if (res.kind === "ok") {
          if (isDownload == true) {
            console.log(res.data);
            const excluded_fields = ['uuid','partner_id','fund_received_date','mode_of_investment','transaction_id','folio_number','soa_letter','allotment_letter','has_allotment_sent']
            const data = convert_json_array_to_csv(res.data,excluded_fields);
            downloadFile({
              data: data,
              fileName: "InvestmentHistory.csv",
              fileType: "text/csv",
            });
          } else {
            console.log(res.data);
            setInvestmentHistoryData(
              rootStore.investmentHistory.investmenthistory
            );
            setFilterData(rootStore.investmentHistory.investmenthistory);
            setTotalCount(res.total);
            setRender(true);
            setDropDownData([
              ...new Set(res.series?.map((v) => v.series_name)),
            ]);
          }
        } else {
          setRender(true);
          errorToast(res.error);
        }
      });
  };

  // request search function
  const requestSearch = (event) => {
    setPage(0);
    setPanNumber(event.target.value.toUpperCase());
    fetchInvestmentHistory(
      event.target.value.toLowerCase(),
      tranche,
      page,
      rowsPerPage,
      isDownload
    );
  };

  const handleDownloadExcel = (event) => {
    setPage(0);
    fetchInvestmentHistory(pan_number, tranche, page, rowsPerPage, true);
  };

  // request dropdown function
  const requestDropDown = (event) => {
    setPage(0);
    setTranche(event.target.value);
    fetchInvestmentHistory(
      pan_number,
      event.target.value,
      page,
      rowsPerPage,
      isDownload
    );
  };

  const handleDownload = async (key) => {
    const stage = process.env.NODE_ENV === "production" ? "prod" : "me";
    rootStore.investmentHistory
      .getInvestmentDocument({
        bucketName: awsConfig[stage].AWSS3.bucket,
        fileName: key,
      })
      .then((res) => {
        if (res.kind === "ok") {
          const blob = new Blob([Buffer.from(res.data.BodyString, "base64")], {
            type: "application/pdf",
          });
          const blobUrl = window.URL.createObjectURL(blob);
          window.open(blobUrl, "_blank", "noopener,noreferrer");
          setRender(true);
        } else {
          setRender(true);
          errorToast(res.error);
        }
      });
  };


  // handle page change function
  const handleChangePage = (event, newPage) => {
    fetchInvestmentHistory(pan_number, tranche, newPage, rowsPerPage);
    setPage(newPage);
  };

  // sort list via name
  // const sortByName = (a, b) => {
  //   if (String(a.name).trim() < String(b.name).trim()) {
  //     return -1;
  //   }
  //   if (String(a.name).trim() > String(b.name).trim()) {
  //     return 1;
  //   }
  //   return 0;
  // };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="investmentHistory_div_container">
          {/* table */}
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="investmentHistory_div_tablecontainer">
                {/* header */}
                <div className="investmentHistory_header_div">
                  {/* text */}
                  <h1 className="investmentHistory_header_div_title">
                    Investment History
                  </h1>
                </div>

                {/* search_bar */}
                <div className="investmentHistory_search_bar_column">
                  <div className="investmentHistory_search_bar_div">
                    <div className="investmentHistory_search_bar">
                      {/* icon */}
                      <SearchIcon
                        style={{
                          fontSize: "20px",
                          paddingRight: "10px",
                          color: "#555555",
                        }}
                      />
                      {/* input box */}
                      <input
                        type="text"
                        placeholder="Search by PAN number"
                        className="investmentHistory_search_bar_input"
                        value={pan_number}
                        onChange={requestSearch}
                      />
                    </div>

                    {dropDownData && dropDownData.length > 0 ? (
                      <>
                        <div className="investmentHistory_dropdown">
                            <FormControl
                              fullWidth
                              sx={{ minWidth: 120,width:'200px' }}
                              size="small"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Tranche and Series
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tranche}
                                label="Tranche and Series"
                                onChange={requestDropDown}
                              >
                                <MenuItem value="" key="-1">
                                  Select Series
                                </MenuItem>
                                {dropDownData &&
                                  dropDownData.length > 0 &&
                                  dropDownData.map((name, index) => (
                                    <MenuItem value={name} key={index}>
                                      {name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        
                      </>
                    ) : null}
                  </div>
                  <div className="download">
                          <Button
                            variant="contained"
                            onClick={handleDownloadExcel}
                            sx={{width:'200px'}}
                          >
                            Download Excel
                          </Button>
                        </div>
                </div>

                {/* table */}
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={tableHeader}>
                          Name
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          PAN Number
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Contact No.
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Email
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Series Name
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Investment Start
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Mode of Investment
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Investment Amount
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          TDS Rate
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Account Name
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Account Number
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          IFSC Code
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Bank Name
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Bank Branch
                        </TableCell>
                        {/* <TableCell align="center" style={tableHeader}>
                          Transaction ID
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Folio Number
                        </TableCell> */}
                        <TableCell align="center" style={tableHeader}>
                          Nominee Name
                        </TableCell>
                        <TableCell align="center" style={tableHeader}>
                          Relationship With User
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...tableHeader,
                            borderRight: "none",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {investmentHistoryData
                        .slice()
                        // .sort(sortByName)
                        // .slice(
                        //   page * rowsPerPage,
                        //   page * rowsPerPage + rowsPerPage
                        // )
                        .map((row, index) => (
                          <TableRow
                            key={row.uuid}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#fff",
                            }}
                          >
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                <p
                                  className="repayment_client_name"
                                  onClick={() =>
                                    navigate.push("/profile", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      pan_number: row.pan_number,
                                    })
                                  }
                                >
                                  {row.name}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.pan_number}
                              </div>
                            </TableCell>

                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>{row.phone}</div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>{row.email}</div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.series_name}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.investment_start}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.mode_of_investment ? "Online" : "Cheque"}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                ₹{row.investment_amount}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.tds_rate}%
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.account_name}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.account_number}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.ifsc_code}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.bank_name}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.branch_name}
                              </div>
                            </TableCell>
                            {/* <TableCell style={tableRow} align="center">
                              <div style={{width: "200px"}}>{row.transaction_id}</div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{width: "200px"}}>{row.folio_number}</div>
                            </TableCell> */}
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.nominee_name}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.relationship_with_user}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                ...tableRow,
                                borderRight: "none",
                              }}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "370px",
                                  justifyContent: "space-around",
                                }}
                              >
                                {/* <p
                                  className="investmentHistory_client_name"
                                  // onClick={() =>
                                  //   navigate.push("/clients/investments", {
                                  //     name: row.name,
                                  //     uuid: row.uuid,
                                  //     net_worth: row.net_worth,
                                  //   })
                                  // }
                                >
                                  View Profile
                                </p> */}
                                <p
                                  className="investmentHistory_client_name"
                                  style={{
                                    cursor:
                                      row.soa_letter && row.soa_letter !== ""
                                        ? "pointer"
                                        : "default",
                                    opacity:
                                      row.soa_letter && row.soa_letter !== ""
                                        ? 1
                                        : 0.5,
                                  }}
                                  onClick={() =>
                                    row.soa_letter && row.soa_letter !== ""
                                      ? handleDownload(`SOA${row.folio_number}.pdf`)
                                      : null
                                  }
                                >
                                  Download SOA
                                </p>
                                <p
                                  className="investmentHistory_client_name"
                                  style={{
                                    cursor:
                                      row.has_allotment_sent &&
                                      row.allotment_letter &&
                                      row.allotment_letter !== ""
                                        ? "pointer"
                                        : "default",
                                    opacity:
                                      row.has_allotment_sent &&
                                      row.allotment_letter &&
                                      row.allotment_letter !== ""
                                        ? 1
                                        : 0.5,
                                  }}
                                  onClick={() =>
                                    row.has_allotment_sent
                                      ? handleDownload(`ALLOT${row.folio_number}.pdf`)
                                      : null
                                  }
                                >
                                  Download Allotment
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {totalCount === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">No row found</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>
                {/* pagination */}
                <div className="pagination_div">
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            )}
          </div>

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
});

export default InvestmentHistory;
