import { useState } from "react";
import "./repayment.css";
import { Typography, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { useStores } from "../../models";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ContentLoadingLoader } from "../../components";
import { Cancel } from "@mui/icons-material";

const repaymentDetail = observer(function RepaymentDetail({
  data,
  type,
  openDialog,
  setClose,
}) {
  const [render, setRender] = useState(true);
  // let [payout_date, setpayoutDate] = useState('');

  // payout_date = data.payout_date && data.payout_date?.toLowerCase() !== 'nan' ? (new Date(data.payout_date)).toISOString().split(' ')[0] : null;
  // handle page change function
  return (
    <div>
      <div className="repayment_bank_div_container">
        {/* table */}
        <div style={{ width: "100%", justifyContent: "center" }}>
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <Dialog sx={{ width: "100%" }} className="dialog" open={openDialog}>
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Cancel onClick={setClose} />
              </div>
              <DialogTitle sx={{ fontSize: "100", fontWeight: "bold" }}>
                Repayment Transaction
              </DialogTitle>
              <DialogContent>
                <div className="dialogText typography">
                  <Typography
                    variant="button"
                    style={{ fontWeight: "bolder", width: "100" }}
                    gutterBottom
                  >
                    Account Name :
                  </Typography>
                  <Typography className="dialogTextData">
                    {data.account_name}
                  </Typography>
                </div>
                <div className="dialogText typography">
                  <Typography
                    variant="button"
                    style={{ fontWeight: "bolder", width: "100" }}
                    gutterBottom
                  >
                    Account No. :
                  </Typography>
                  <Typography className="dialogTextData">
                    {data.account_number}
                  </Typography>
                </div>
                <div className="dialogText typography">
                  <Typography
                    variant="button"
                    style={{ fontWeight: "bolder", width: "100" }}
                    gutterBottom
                  >
                    Branch :
                  </Typography>
                  <Typography className="dialogTextData">
                    {data.bankDetailBranchName}
                  </Typography>
                </div>
                <div className="dialogText typography">
                  <Typography
                    variant="button"
                    style={{ fontWeight: "bolder", width: "100" }}
                    gutterBottom
                  >
                    IFSC Code :
                  </Typography>
                  <Typography className="dialogTextData">
                    {data.ifsc_code}
                  </Typography>
                </div>
                {/* {type === 'upcoming' ? (<> */}
                {/* <div className="dialogText typography">
                  <Typography
                    variant="button"
                    style={{ fontWeight: "bolder", width: "100" }}
                    gutterBottom
                  >
                    Transaction ID :
                  </Typography>
                  <Typography className="dialogTextData">
                    {data.repaymentTransactionId || ''}
                  </Typography>
                </div> */}
                {type === "past" ? (
                  <div className="dialogText typography">
                    <Typography
                      variant="button"
                      style={{ fontWeight: "bolder", width: "100" }}
                      gutterBottom
                    >
                      Payout Date :
                    </Typography>
                    <Typography className="dialogTextData">
                      {data.payout_date || ""}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}

                {/* </>): null} */}
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
});

export default repaymentDetail;
