import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  InvestorProfile,
  InvestorProfileModel,
  InvestorProfileSnapshot,
} from "../investorProfile/investorProfile";
import { Investor_API } from "../../../services/api";

// make the instance of api
const api = new Investor_API();

export const InvestorProfileStoreModel = types
  .model("InvestorProfileStore")
  .props({
    investorProfile: types.optional(types.array(InvestorProfileModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param InvestorProfileSnapshot
     */
    saveInvestorProfile: (investorProfileSnapshot: InvestorProfileSnapshot[]) => {
      try {
        const investorProfileModel: InvestorProfile[] = investorProfileSnapshot.map((c) =>
          InvestorProfileModel.create(c)
        );
        self.investorProfile.replace(investorProfileModel);
      } catch (e) {
        console.log(e)
      }
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getInvestorProfile: flow(function* (pan_number) {
      const result = yield api.getInvestorProfile(pan_number);
      if (result.kind === "ok") {
        self.saveInvestorProfile(result.profile);
        return { kind: "ok", profile: result.profile };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type InvestorProfileStoreType = Instance<typeof InvestorProfileStoreModel>;
export interface InvestorProfileStore extends InvestorProfileStoreType { }

type InvestorProfileStoreSnapshotType = SnapshotOut<typeof InvestorProfileStoreModel>;
export interface InvestorProfileStoreSnapshot extends InvestorProfileStoreSnapshotType { }
