import React from "react";
import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";

const CustomComponent = withStyles({
  tooltip: {
    color: "#fff",
    fontSize: 14,
    lineHeight: 1.5,
    fontFamily: "Asap",
    backgroundColor: "#454545",
  },
  arrow: {
    color: "#454545",
  },
})(Tooltip);

export default function CustomTooltip(props) {
  return (
    <CustomComponent title={props.info} arrow placement="top">
      {props.children}
    </CustomComponent>
  );
}
