import React, { useEffect, useState } from "react";
import "./investor.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@mui/material";
import { useStores } from "../../models";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth, Storage } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { errorToast, convertAmount, successToast, convert_json_array_to_csv } from "../../utils/helper";
import { ContentLoadingLoader, SampleToast } from "../../components";
import awsConfig from "../../constant/aws-exports";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  marginLeft: "1%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
  height: "auto",
  minWidth: "900px",
  overflowY: "scroll",
};

const tableHeader = (e) => {
  return {
    backgroundColor: "#ececec",
    fontWeight: "600",
    fontSize: 16,
    fontFamily: "Asap",
    color: "#1f1f1f",
    borderRight: "1px solid #c7c7c7",
    paddingTop: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "15px",
    width: e ? "200%" : "inherit",
  };
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingBottom: "10px",
};


const Investor = observer(function Investors(props) {
  const navigate = useHistory();
  const [investorData, setInvestorData] = useState([]);
  const [page, setPage] = useState(0);
  const [pan_number, setPanNumber] = useState("");
  const [totalInvestors, setTotalInvestors] = useState(0);
  const rowsPerPage = 10;
  const [render, setRender] = useState(true);
  const rootStore = useStores();
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        fetchInvestor(null, page, rowsPerPage);
      } catch (err) {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, [props]);

  console.log(isLogin);

  useEffect(() => {}, [investorData]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  // fetch the client list
  const fetchInvestor = (
    pan_number = null,
    page = null,
    pagesize = null,
    isDownload = null
  ) => {
    rootStore.investor
      .getInvestor(pan_number, page, pagesize, isDownload)
      .then((res) => {
        if (res.kind === "ok") {
          if (isDownload == true) {
            const data = convert_json_array_to_csv(res.data,['uuid']);
            downloadFile({
              data: data,
              fileName: "Investor.csv",
              fileType: "text/csv",
            });
          } else {
            console.log(res);
            setInvestorData(rootStore.investor.investor);
            setTotalInvestors(res.total);
            setRender(true);
          }
        } else {
          setRender(true);
          errorToast(res.error);
        }
      });
  };

  const handleDownloadExcel = (event) => {
    setPage(0);
    fetchInvestor(pan_number, page, rowsPerPage, true);
  };
  // request search function
  const requestSearch = (event) => {
    setPage(0);
    setPanNumber(event.target.value.toUpperCase());
    fetchInvestor(event.target.value.toUpperCase(), page, rowsPerPage, isDownload);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchInvestor(pan_number, newPage, rowsPerPage, isDownload);
  };

  // sort list via name
  const sortByName = (a, b) => {
    if (String(a.name).trim() < String(b.name).trim()) {
      return -1;
    }
    if (String(a.name).trim() > String(b.name).trim()) {
      return 1;
    }
    return 0;
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  console.log(investorData);

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="investor_div_container">
          {/* table */}
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="investor_div_tablecontainer">
                {/* header */}
                <div className="investor_header_div">
                  {/* text */}
                  <h1 className="investor_header_div_title">Investors</h1>
                </div>

                {/* search_bar */}
                <div className="investor_search_bar_div">
                  <div className="investor_search_bar">
                    {/* icon */}
                    <SearchIcon
                      style={{
                        fontSize: "20px",
                        paddingRight: "10px",
                        color: "#555555",
                      }}
                    />
                    {/* input box */}
                    <input
                      type="text"
                      placeholder="Search by PAN number"
                      className="investor_search_bar_input"
                      value={pan_number}
                      onChange={requestSearch}
                    />
                  </div>
                  <div className="investor_download">
                    <Button variant="contained" onClick={handleDownloadExcel} sx={{width:'200px'}}>
                      Download Excel
                    </Button>
                  </div>
                </div>

                {/* table */}
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={tableHeader(investorData[0]?.name)}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(investorData[0]?.name)}
                        >
                          PAN Number
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(investorData[0]?.name)}
                        >
                          Mobile Number
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(investorData[0]?.name)}
                        >
                          Email ID
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...tableHeader(investorData[0]?.name),
                            borderRight: "none",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {console.log(
                        " start",
                        page * rowsPerPage,
                        "end",
                        page * rowsPerPage + rowsPerPage
                      )}

                      {investorData
                        .slice()
                        .sort(sortByName)
                        // .slice(
                        //   page * rowsPerPage,
                        //   page * rowsPerPage + rowsPerPage
                        // )
                        .map((row, index) => (
                          <TableRow
                            key={row.uuid}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#fff",
                            }}
                          >
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>
                                <p
                                  className="repayment_client_name"
                                  onClick={() =>
                                    navigate.push("/profile", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      pan_number: row.pan_number,
                                    })
                                  }
                                >
                                  {row.name}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.pan_number}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>{row.phone}</div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>{row.email}</div>
                            </TableCell>
                            <TableCell
                              style={{
                                ...tableRow,
                                borderRight: "none",
                              }}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "500px",
                                  justifyContent: "space-around",
                                }}
                              >
                                <p
                                  className="investor_client_name"
                                  onClick={() =>
                                    navigate.push("/repayment", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      pan_number: row.pan_number,
                                      type: "upcoming",
                                    })
                                  }
                                >
                                  Upcoming Repayments
                                </p>
                                <p
                                  className="investor_client_name"
                                  onClick={() =>
                                    navigate.push("/repayment", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      pan_number: row.pan_number,
                                      type: "past",
                                    })
                                  }
                                >
                                  Past Repayments
                                </p>
                                <p
                                  className="investor_client_name"
                                  onClick={() =>
                                    navigate.push("/investmentHistory", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      pan_number: row.pan_number,
                                    })
                                  }
                                >
                                  Investment History
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {totalInvestors === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">No row found</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>
                {/* pagination */}
                <div className="pagination_div">
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalInvestors}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            )}
          </div>
          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
});

export default Investor;
