import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Profile,
  ProfileModel,
  ProfileSnapshot,
} from "../profile/profile";
import { Profile_API } from "../../../services/api";

// make the instance of api
const api = new Profile_API();

export const ProfileStoreModel = types
  .model("ProfileStore")
  .props({
    Profile: types.optional(types.array(ProfileModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param ProfileSnapshot
     */
    saveProfile: (ProfileSnapshot: ProfileSnapshot[]) => {
      const profileModel: Profile[] = ProfileSnapshot.map((c) =>
      ProfileModel.create(c)
      );
      self.Profile.replace(profileModel);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getProfile: flow(function* () {
      const result = yield api.getProfile();
      if (result.kind === "ok") {
        self.saveProfile(result.Profile);
        return { kind: "ok" };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type ProfileStoreType = Instance<typeof ProfileStoreModel>;
export interface ProfileStore extends ProfileStoreType {}

type ProfileStoreSnapshotType = SnapshotOut<typeof ProfileStoreModel>;
export interface ProfileStoreSnapshot extends ProfileStoreSnapshotType {}
