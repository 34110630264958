import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const OverviewModel = types
  .model("OverviewModel")
  .props({
    uuid: types.optional(types.string, ""),
    pan_number: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    referral_fee: types.maybeNull(types.number),
    phone: types.optional(types.string,""),
    email: types.optional(types.string, ""),
    total_commission_accrued: types.optional(types.number,0),
    total_clients: types.optional(types.number,0)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type OverviewModelDataType = Instance<typeof OverviewModel>;
export interface Overview extends OverviewModelDataType {}

type OverviewModelSnapshotType = SnapshotOut<typeof OverviewModel>;
export interface OverviewSnapshot extends OverviewModelSnapshotType {}
