import { API } from "aws-amplify";
import * as Types from "../api.types";
import { OpenPoolSnapshot } from "../../../models";

export class Pools_API {
  /**
   * @description this function is for getting the open pools list
   * @returns this function returns the open pools list
   */
  async getOpenPoolsAPI(): Promise<Types.GetOpenPoolResult> {
    // convert the raw data into actual data
    const dataConverter = (raw: any) => {
      return {
        id: raw.id,
        pool_title:
          raw.template === undefined ? "undefined" : raw.template.title,
        risk_rate:
          raw.risk_rating === undefined ? "undefined" : raw.risk_rating.title,
        badge_title: raw.badge === null ? "undefined" : raw.badge.title,
        tenure: raw.template === undefined ? "undefined" : raw.template.tenure,
        tenure_type:
          raw.template === undefined
            ? "undefined"
            : raw.template.tenure_type === 1
            ? "Months"
            : "Days",
        interest_rate:
          raw.avg_interest_rate === undefined
            ? "undefined"
            : raw.avg_interest_rate,
        minimum_investment:
          raw.minimum_investment_amount === undefined
            ? "undefined"
            : String(raw.minimum_investment_amount),
        start_date:
          raw.issue === undefined ? "undefined" : raw.issue.start.formatted,
        end_date:
          raw.issue === undefined ? "undefined" : raw.issue.end.formatted,
        demand_amount:
          raw.total_demand === undefined ? "" : String(raw.total_demand),
        totle_investment:
          raw.invested_amount === undefined
            ? "undefined"
            : String(raw.invested_amount),
        is_shielded:
          raw.is_shielded === undefined ? "undefined" : raw.is_shielded,
        description:
          raw.description === undefined ? "undefined" : raw.description,
        logo_color:
          raw.template === undefined
            ? "#123456"
            : raw.template.title === "Ultra Short Term"
            ? "#E39436"
            : raw.template.title === "Short Term"
            ? "#7F53DC"
            : raw.template.title === "Medium Term"
            ? "#1CC29F"
            : "#123456",
        badge_color:
          raw.badge === null
            ? "undefined"
            : raw.badge.title === "Trending"
            ? "#9535C2"
            : raw.badge.title === "Hot Picks"
            ? "#4BA8D0"
            : "#123456",
        risk_color:
          raw.risk_rating === undefined
            ? "#123456"
            : String(raw.risk_rating.title).startsWith("A")
            ? "#12924D"
            : String(raw.risk_rating.title).startsWith("B")
            ? "#E29705"
            : "#F04237",
      };
    };

    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/pools", {});
      const rawData = response.data;
      const convertedData: OpenPoolSnapshot[] = rawData.map(dataConverter);
      return { kind: "ok", openpools: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for get specific pool by its id
   * @param id
   * @returns json data
   */
  async getPoolById(id: any): Promise<Types.GetPoolByIdResult> {
    // convert the raw data into actual data
    const dataConverter = (raw: any) => {
      return {
        id: raw.id,
        pool_title:
          raw.template === undefined ? "undefined" : raw.template.title,
        risk_rate:
          raw.risk_rating === undefined ? "undefined" : raw.risk_rating.title,
        badge_title: raw.badge === null ? "undefined" : raw.badge.title,
        tenure: raw.template === undefined ? "undefined" : raw.template.tenure,
        tenure_type:
          raw.template === undefined
            ? "undefined"
            : raw.template.tenure_type === 1
            ? "Months"
            : "Days",
        interest_rate:
          raw.avg_interest_rate === undefined
            ? "undefined"
            : raw.avg_interest_rate,
        minimum_investment:
          raw.minimum_investment_amount === undefined
            ? "undefined"
            : String(raw.minimum_investment_amount),
        start_date:
          raw.issue === undefined ? "undefined" : raw.issue.start.formatted,
        end_date:
          raw.issue === undefined ? "undefined" : raw.issue.end.formatted,
        total_investments:
          raw.invested_amount === undefined
            ? "undefined"
            : String(raw.invested_amount),
        is_shielded:
          raw.is_shielded === undefined ? "undefined" : raw.is_shielded,
        description:
          raw.description === undefined ? "undefined" : raw.description,
        logo_color:
          raw.template === undefined
            ? "#123456"
            : raw.template.title === "Ultra Short Term"
            ? "#E39436"
            : raw.template.title === "Short Term"
            ? "#7F53DC"
            : raw.template.title === "Medium Term"
            ? "#1CC29F"
            : "#123456",
        badge_color:
          raw.badge === null
            ? "undefined"
            : raw.badge.title === "Trending"
            ? "#9535C2"
            : raw.badge.title === "Hot Picks"
            ? "#4BA8D0"
            : "#123456",
        risk_color:
          raw.risk_rating === undefined
            ? "#123456"
            : String(raw.risk_rating.title).startsWith("A")
            ? "#12924D"
            : String(raw.risk_rating.title).startsWith("B")
            ? "#E29705"
            : "#F04237",
        partners: raw.partners,
      };
    };

    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", `/pools/${id}`, {});
      const rawData = response.data;
      const convertedData: any = dataConverter(rawData);
      return { kind: "ok", getpoolbyid: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for send the payment link to the investors
   * @param data
   * @returns this return the sucess and failure message
   */
  async sendPaymentLink(data: any): Promise<Types.GetSendPaymentLink> {
    // payload
    const params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/ifa/links", params);
      return { kind: "ok", data: response };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
