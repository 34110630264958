import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const InvestorModel = types
  .model("InvestorModel")
  .props({
    uuid: types.optional(types.string, ""),
    pan_number: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type InvestorModelDataType = Instance<typeof InvestorModel>;
export interface Investor extends InvestorModelDataType {}

type InvestorModelSnapshotType = SnapshotOut<typeof InvestorModel>;
export interface InvestorSnapshot extends InvestorModelSnapshotType {}
