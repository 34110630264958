import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  OpenPool,
  OpenPoolModel,
  OpenPoolSnapshot,
} from "../open-pool/open-pool";
import { Pools_API } from "../../../services/api";

// make the instance of api
const api = new Pools_API();

export const OpenPoolStoreModel = types
  .model("OpenPoolStore")
  .props({
    openpools: types.optional(types.array(OpenPoolModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    saveOpenPools: (openpoolSnapshot: OpenPoolSnapshot[]) => {
      const openpoolsModel: OpenPool[] = openpoolSnapshot.map((c) =>
        OpenPoolModel.create(c)
      );
      self.openpools.replace(openpoolsModel);
    },
  }))
  .actions((self) => ({
    getOpenPools: flow(function* () {
      const result = yield api.getOpenPoolsAPI();
      if (result.kind === "ok") {
        self.saveOpenPools(result.openpools);
        return { kind: "ok" };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type OpenPoolStoreType = Instance<typeof OpenPoolStoreModel>;
export interface OpenPoolStore extends OpenPoolStoreType {}

type OpenPoolStoreSnapshotType = SnapshotOut<typeof OpenPoolStoreModel>;
export interface OpenPoolStoreSnapshot extends OpenPoolStoreSnapshotType {}
