import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Commission,
  CommissionModel,
  CommissionSnapshot,
} from "../commission/commission";
import { Commission_API } from "../../../services/api";

// make the instance of api
const api = new Commission_API();

export const CommissionStoreModel = types
  .model("CommissionStore")
  .props({
    commission: types.optional(types.array(CommissionModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param CommissionSnapshot
     */
    saveCommission: (CommissionSnapshot: CommissionSnapshot[]) => {
      const commissionModel: Commission[] = CommissionSnapshot.map((c) =>
        CommissionModel.create(c)
      );
      self.commission.replace(commissionModel);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getCommission: flow(function* (ncd, page, pageSize, isDownload) {
      const result = yield api.getCommission(ncd, page+1, pageSize, isDownload);
      if (result.kind === "ok") {
        self.saveCommission(result.data);
        return { kind: "ok", data: result.data, total: result.total, ncd_series: result.ncd_series };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

    /**
     * @description this function is for get the client list
     */
     getCommissionInvestment: flow(function* (ncd, page, pageSize, isDownload) {
      const result = yield api.getCommissionInvestment(ncd, page+1, pageSize, isDownload);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.data, total: result.total, page: result.page };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type CommissionStoreType = Instance<typeof CommissionStoreModel>;
export interface CommissionStore extends CommissionStoreType {}

type CommissionStoreSnapshotType = SnapshotOut<typeof CommissionStoreModel>;
export interface CommissionStoreSnapshot extends CommissionStoreSnapshotType {}
