import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const InvestorProfileModel = types
  .model("InvestorProfileModel")
  .props({
    uuid: types.optional(types.string, ""),
    pan_number: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    phone: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    address: types.optional(types.string, ""),
    clientUUID: types.optional(types.string, ""),
    id: types.optional(types.number, 0),
    pincode: types.optional(types.string, ""),
    category: types.optional(types.number, 0),
    dob: types.optional(types.string, ""),
    dob_format: types.optional(types.string, ""),
    mode: types.optional(types.number, 0),
    demat_id: types.optional(types.string, ""),
    partner_id: types.optional(types.string, ""),
    trustee_name: types.optional(types.string, ""),
    address_front_image: types.maybeNull(types.string),
    address_back_image: types.maybeNull(types.string),
    board_resolution_image: types.maybeNull(types.string),
    certificate_of_incorporation: types.maybeNull(types.string),
    demat_image: types.maybeNull(types.string),
    other: types.maybeNull(types.string),
    pan_image: types.maybeNull(types.string),
    documentUUID: types.maybeNull(types.string),
    nomineeId: types.optional(types.number, 0),
    bankDetailUUID: types.optional(types.string, ""),
    account_number: types.optional(types.string, ""),
    branch_name: types.optional(types.string, ""),
    ifsc_code: types.optional(types.string, ""),
    bankDetailAddress: types.optional(types.string, ""),
    bankDetailName: types.optional(types.string, ""),
    nomineePanNumber: types.optional(types.string, ""),
    nomineePhone: types.optional(types.string, ""),
    nomineeName: types.optional(types.string, ""),
    nomineeEmail: types.optional(types.string, ""),
    relationship_with_user: types.optional(types.string, ""),
    nomineeUUID: types.optional(types.string, ""),
    partnerName: types.optional(types.string, ""),
    bank_with_account_number: types.optional(types.string, "")
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type InvestorProfileModelDataType = Instance<typeof InvestorProfileModel>;
export interface InvestorProfile extends InvestorProfileModelDataType {}

type InvestorProfileModelSnapshotType = SnapshotOut<typeof InvestorProfileModel>;
export interface InvestorProfileSnapshot extends InvestorProfileModelSnapshotType {}
