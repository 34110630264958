import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

/**
 * @author Abhay Upadhyay
 * @description This function is for success message toast
 * @param {string} msg
 * @param {number} status
 * @returns Toast message
 */

export function successToast(msg, status = false) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    icon: () =>
      status ? (
        <AnnouncementIcon
          style={{
            fontSize: "24px",
            color: "#409E13",
          }}
        />
      ) : (
        <CheckCircleIcon
          style={{
            fontSize: "24px",
            color: "#409E13",
          }}
        />
      ),
    style: {
      backgroundColor: "rgb(240,249,236)",
      border: "1px solid #409E13",
      color: "#409E13",
      fontSize: "14px",
      fontFamily: "Asap",
    },
  });
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for error message toast
 * @param {string} msg
 * @param {number} status
 * @returns Toast message
 */

export function errorToast(msg, status = false) {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    icon: () =>
      status ? (
        <InfoIcon
          style={{
            fontSize: "24px",
            color: "#D92B2B",
          }}
        />
      ) : (
        <ReportProblemIcon
          style={{
            fontSize: "24px",
            color: "#D92B2B",
          }}
        />
      ),
    style: {
      backgroundColor: "rgb(247,224,225)",
      border: "1px solid #D92B2B",
      color: "#D92B2B",
      fontSize: "14px",
      fontFamily: "Asap",
    },
  });
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for formatting amount into Lakh and Cr.
 * @param {any} value
 * @returns formatted date
 */

export function convertAmount(value) {
  var val = Math.abs(value);
  return amountComma(val.toFixed(2));
  // if (val >= 10000000) {
  //   val = (val / 10000000).toFixed(2) + " Cr";
  //   return amountComma(val);
  // } else if (val >= 100000) {
  //   val = (val / 100000).toFixed(2) + " Lac";
  //   return amountComma(val);
  // } else {
  //   return amountComma(val.toFixed(2));
  // }
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for adding comma between amount in (IN Rupees) format
 * @param {any} data
 * @returns amount with comma seperator
 */

export function amountComma(data) {
  let req = data;
  let res;
  if (req.toString().split(".")[0].length > 3) {
    res =
      req
        .toString()
        .substring(0, req.toString().split(".")[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      "," +
      req.toString().substring(req.toString().split(".")[0].length - 3);
  } else {
    res = req.toString();
  }
  return res;
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is for formatting raw date into actual date
 * @param {string} str
 * @returns formatted date
 */

export function getFormattedDate(str, repayment = false) {
  let output;
  if (repayment) {
    let data = str.split(" ");
    let d = data[0].substring(0, 2);
    let m = data[1].replace(",", "");
    let y = data[2];
    output = `${d}/${m}/${y}`;
  } else {
    var arr = str.split(" ");
    var [y, m, d] = arr[0].split("-");
    if (d < 10) {
      d = "0" + d.substring(1);
    }
    output = `${d}/${m}/${y}`;
  }
  return output;
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is to get unique uuid
 * @returns unique uuid
 */

export async function getUniqueUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/**
 * @description End
 */

/**
 * @author Abhay Upadhyay
 * @description This function is to get file name extension
 * @returns file extension
 */

export async function getFileExtension(event) {
  if (
    !event ||
    !event.target ||
    !event.target.files ||
    event.target.files.length === 0
  ) {
    return;
  }
  const name = event.target.files[0].name;
  const lastDot = name.lastIndexOf(".");
  const ext = name.substring(lastDot + 1);
  return ext;
}
/**
 * @description End
 */

/**
 * @author Kinjal
 * @description This function is to convert json object to csv
 */

 export function convert_json_array_to_csv(json_array,excluded_fields=[],map_fields={}) {
    var items = json_array;
    var new_json = [];
    for(var i=0;i<items.length;i++){
      var ob = {...items[i]};
      for(let field of excluded_fields){
        delete ob[field]
      }
      new_json.push(ob);
    }
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const rawHeader=Object.keys(new_json[0]);
    const header = Object.keys(new_json[0]).map((item)=> map_fields[item]?map_fields[item]:item)
    const csv = [
      header.join(","), // header row first
      ...new_json.map((row) =>
        rawHeader
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    return csv;
};

/**
* Method to convert number into Indian Rupees.
* @param {*} number
*/
export const convertIntoINR= (number,decimalCount=0)=> {
  if (!number || isNaN(number)) {
    return number;
  }

  const ans = number.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  });

  return ans.substring(1);
}
