import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const titleStyle = {
  width: "400px",
  fontFamily: "Asap",
  color: "#414141",
  fontSize: 18,
};

const contentStyle = {
  fontFamily: "Asap",
  color: "#41414199",
  fontSize: 16,
};

const buttonStyle = {
  fontFamily: "Asap",
  textTransform: "none",
  color: "#3a86ff",
  fontSize: 14,
};

export default function AlertBox(props) {
  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.no}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <DialogTitle id="alert-dialog-title" style={titleStyle}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={contentStyle}>
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={buttonStyle} onClick={props.yes}>
            {props.positivebutton}
          </Button>
          <Button style={buttonStyle} onClick={props.no}>
            {props.negativebutton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
