import * as React from "react";
import "./common-sidebar.css";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AlertBox from "../alert-box/alert";
import routes from "../../routes";

const CommonSidebar = function () {
  const navigate = useHistory();
  const param = useLocation();
  const [logoutAlert, setLogoutAlert] = React.useState(false);

  // log out function
  const onLogOut = async () => {
    await Auth.signOut()
      .then(() => {
        navigate.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="common_sidebar_main_container">
      {/* menu div */}
      <div className="common_sidebar_menu_div">
        {/* menu items */}
        <div className="common_sidebar_menu_item_div">
          {routes.map(({ label, id, path, showInSidebar }) =>
            showInSidebar ? (
              <div
                className="common_sidebar_menu_text_div"
                key={id}
                style={{
                  backgroundColor:
                    param.pathname === path ? "#E9F2FF" : undefined,
                }}
                onClick={() => navigate.push(path)}
              >
                <p
                  className="common_sidebar_menu_text"
                  style={{
                    color: param.pathname === path ? "#3A86FF" : "#272727",
                  }}
                >
                  {label}
                </p>
              </div>
            ) : null
          )}
        </div>

        {/* logout div */}
        <div className="common_sidebar_menu_logout_div">
          {/* loogout button */}
          <div
            className="common_sidebar_menu_logout_button_container"
            onClick={() => setLogoutAlert(true)}
          >
            {/* logout icon */}
            <PowerSettingsNewIcon
              style={{
                fontSize: "18px",
                marginRight: "10px",
                color: "#fff",
              }}
            />
            {/* logout text */}
            <p className="common_sidebar_menu_logout_text">Logout</p>
          </div>
        </div>
      </div>
      {/* show logout alert */}
      {logoutAlert ? (
        <AlertBox
          show={logoutAlert}
          title="Log Out"
          content="Are you sure you want to logout?"
          positivebutton="Yes"
          negativebutton="No"
          yes={onLogOut}
          no={() => setLogoutAlert(false)}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default CommonSidebar;
