import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const CommissionModel = types
  .model("CommissionModel")
  .props({
    uuid: types.optional(types.string, ""),
    series_name: types.optional(types.string, ""),
    commission_rate: types.optional(types.number, 0),
    commission_accrued: types.optional(types.number,0),
    ncd_id: types.optional(types.string,'')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type CommissionModelDataType = Instance<typeof CommissionModel>;
export interface Commission extends CommissionModelDataType {}

type CommissionModelSnapshotType = SnapshotOut<typeof CommissionModel>;
export interface CommissionSnapshot extends CommissionModelSnapshotType {}
