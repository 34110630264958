import { AuthPage, Commission, InvestmentHistory, Investor, Overview, Pagenotfound, Profile, Repayment, RepaymentDetail } from "./pages";


const routes = [
  {
    label: "Auth",
    id: "auth",
    path: "/",
    showInSidebar: false,
    component : (props)=>(
      <AuthPage {...props}/>
    )
  },
  {
    label: "Overview",
    id: "overview",
    path: "/overview",
    showInSidebar: true,
    component : (props)=>(
      <Overview {...props}/>
    )
  },
    {
      label: "Investors",
      id: "investors",
      path: "/investor",
      showInSidebar: true,
      component : (props)=>(
        <Investor {...props}/>
      )
    },
    {
      label: "Investment History",
      id: "investment_history",
      path: "/investmentHistory",
      showInSidebar: true,
      component : (props)=>(
        <InvestmentHistory {...props}/>
      )
    },
    {
      label: "Repayments",
      id: "repayments",
      path: "/repayment",
      showInSidebar: true,
      component : (props)=>(
        <Repayment {...props}/>
      )
    },
    {
      label: "Commissions",
      id: "commission",
      path: "/commission",
      showInSidebar: true,
      component : (props)=>(
        <Commission {...props}/>
      )
    },
    {
      label: "Profile",
      id: "profile",
      path: "/profile",
      showInSidebar: false,
      component : (props)=>(
        <Profile {...props}/>
      )
    },
    {
      label: "Repayment Details",
      id: "repayments",
      path: "/repaymentDetail",
      showInSidebar: false,
      component : (props)=>(
        <RepaymentDetail {...props}/>
      )
    },
    {
      label: "Page not Found",
      id: "page_not_found",
      path: "/404",
      showInSidebar: false,
      component : (props)=>(
        <Pagenotfound {...props}/>
      )
    },
];

export default routes;