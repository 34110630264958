import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Repayment,
  RepaymentModel,
  RepaymentSnapshot,
} from "../repayment/repayment";
import { Repayment_API } from "../../../services/api";

// make the instance of api
const api = new Repayment_API();

export const RepaymentStoreModel = types
  .model("RepaymentStore")
  .props({
    repayment: types.optional(types.array(RepaymentModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param InvestorSnapshot
     */
    saveRepayment: (RepaymentSnapshot: RepaymentSnapshot[]) => {
      const repaymentModel: Repayment[] = RepaymentSnapshot.map((c) =>
        RepaymentModel.create(c)
      );
      self.repayment.replace(repaymentModel);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getPastRepayment: flow(function* (pan_number, series_name, page, pagesize, isDownload) {
      const result = yield api.getPastRepayment(pan_number, series_name, page, pagesize, isDownload);
      if (result.kind === "ok") {
        self.saveRepayment(result.repayment);
        return { kind: "ok", data: result.repayment, total: result.total, series:result.series };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getUpcomingRepayment: flow(function* (pan_number, series_name, page, pagesize, isDownload) {
      const result = yield api.getUpcomingRepayment(pan_number, series_name, page, pagesize, isDownload);
      if (result.kind === "ok") {
        self.saveRepayment(result.repayment);
        return { kind: "ok", data: result.repayment, total: result.total, series:result.series };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type RepaymentStoreType = Instance<typeof RepaymentStoreModel>;
export interface RepaymentStore extends RepaymentStoreType { }

type RepaymentStoreSnapshotType = SnapshotOut<typeof RepaymentStoreModel>;
export interface RepaymentStoreSnapshot extends RepaymentStoreSnapshotType { }
