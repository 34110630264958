import { API } from "aws-amplify";
import { InvestmentHistorySnapshot } from "../../../models";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class InvestmentHistory_API {
  /**
     * @description This function is for converting raw data into the actual data
     * @param raw
     * @returns it return the actual data that can be displayed
     */
  dataConverter = (raw: any) => {
    return {
      uuid: raw.uuid,
      name: !raw.name ? "" : raw.name,
      partner_id: !raw.partner_id  ? "" : raw.partner_id,
      pan_number: !raw.pan_number ? "" : raw.pan_number,
      phone: !raw.phone ? "" : raw.phone,
      email: !raw.email? "" : raw.email,
      series_name: !raw.series_name ? "" : raw.series_name,
      fund_received_date: !raw.fund_received_date? "" : raw.fund_received_date,
      investment_start: !raw.investment_start ? "" : raw.investment_start,
      mode_of_investment: !raw.mode_of_investment ? "" : String(raw.mode_of_investment),
      investment_amount: !raw.investment_amount? "" : String(raw.investment_amount),
      tds_rate: !raw.tds_rate ? 0 : raw.tds_rate,
      account_name: !raw.account_name? "" : raw.account_name,
      account_number: !raw.account_number? "" : raw.account_number,
      ifsc_code: !raw.ifsc_code ? "" : raw.ifsc_code,
      bank_name: !raw.bank_name  ? "" : raw.bank_name,
      branch_name: !raw.branch_name ? "" : raw.branch_name,
      transaction_id: !raw.transaction_id  ? "" : raw.transaction_id,
      folio_number: !raw.folio_number? "" : raw.folio_number,
      nominee_name: !raw.nominee_name ? "" : raw.nominee_name,
      relationship_with_user: !raw.relationship_with_user  ? "" : raw.relationship_with_user,
      soa_letter: !raw.soa_letter? "" : raw.soa_letter,
      allotment_letter: !raw.allotment_letter ? "" : raw.allotment_letter,
      has_allotment_sent: !raw.has_allotment_sent? 0 : raw.has_allotment_sent,
    };
  };
  /**
   * @description This function is to get client list of ifa
   * @returns it returns user overall client list data.
   */
  async getInvestmentHistory(pan_number: string, series_name: string, page: number, pagesize: number, isDownload: boolean): Promise<Types.GetInvestmentHistoryResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/clientInvestment", {
        queryStringParameters: {
          pan_number,
          series_name,
          page,
          pagesize,
          isDownload
        },
      });
      const series: any = response.data.series_name;
      const rawData = response.data;
      const data = rawData?.data || [];
      if (isDownload == true) {
        const convertedData: InvestmentHistorySnapshot[] = data.map(
          this.dataConverter
        );
        return { kind: "ok", investmenthistory: convertedData, series: series, total: response.data.total };
      }
      const convertedData: InvestmentHistorySnapshot[] = data.length!=0?JSON.parse(data).map(
        this.dataConverter
      ):[];
      return { kind: "ok", investmenthistory: convertedData, series: series, total: response.data.total };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  async getInvestmentDocument(data: any): Promise<Types.GetInvestmentsDocumentResult> {
    // payload
    const params = {
      body: data,
      data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/partner/getDocument", params);
      const rawData = response.data;
      return { kind: "ok", data: rawData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
