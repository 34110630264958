import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  Overview,
  OverviewModel,
  OverviewSnapshot,
} from "../overview/overview";
import { Overview_API } from "../../../services/api";

// make the instance of api
const api = new Overview_API();

export const OverviewStoreModel = types
  .model("OverviewStore")
  .props({
    overview: types.optional(types.array(OverviewModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param OverviewSnapshot
     */
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getOverview: flow(function* () {
      const result = yield api.getOverview();
      if (result.kind === "ok") {
        return { kind: "ok",data: result.data};
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type OverviewStoreType = Instance<typeof OverviewStoreModel>;
export interface OverviewStore extends OverviewStoreType {}

type OverviewStoreSnapshotType = SnapshotOut<typeof OverviewStoreModel>;
export interface OverviewStoreSnapshot extends OverviewStoreSnapshotType {}
