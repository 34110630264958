import React from "react";
import Loader from "react-loader-spinner";

const ContentLoadingLoader = () => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        marginTop: "100px",
        marginLeft: "220px",
      }}
    >
      <Loader
        type="TailSpin"
        color="#414141"
        height={80}
        width={80}
        style={{
          marginTop: "150px",
        }}
      />
    </div>
  );
};

export default ContentLoadingLoader;
