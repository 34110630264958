import { API } from "aws-amplify";
import { RepaymentSnapshot } from "../../../models";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class Repayment_API {
  /**
     * @description This function is for converting raw data into the actual data
     * @param raw
     * @returns it return the actual data that can be displayed
     */
  dataConverter = (raw: any) => {
    return {
      account_name: !raw.account_name? '' : raw.account_name,
      account_number: !raw.account_number? '' : raw.account_number,
      bankDetailBranchName: !raw.bankDetailBranchName  ? '' : raw.bankDetailBranchName,
      bankDetailName: !raw.bankDetailName ? '' : raw.bankDetailName,
      bank_id: !raw.bank_id ? '' : raw.bank_id,
      client_id: !raw.client_id ? '' : raw.client_id,
      due_date: !raw.due_date ? '' : raw.due_date,
      email: !raw.email  ? '' : raw.email,
      face_value: !raw.face_value ? 0 : raw.face_value,
      ifsc_code: !raw.ifsc_code? '' : raw.ifsc_code,
      interest: !raw.interest ? 0 : raw.interest,
      investmentId: !raw.investmentId ? 0 : raw.investmentId,
      investmentTransactionId: !raw.investmentTransactionId  ? '' : raw.investmentTransactionId,
      investmentUUID: !raw.investmentUUID  ? '' : raw.investmentUUID,
      investment_amount: !raw.investment_amount  ? 0 : raw.investment_amount,
      investment_start: !raw.investment_start ? '' : raw.investment_start,
      mode_of_investment: !raw.mode_of_investment ? 0 : raw.mode_of_investment,
      name: !raw.name? '' : raw.name,
      ncd_id: !raw.ncd_id  ? '' : raw.ncd_id,
      pan_number: !raw.pan_number ? '' : raw.pan_number,
      payout_date: !raw.payout_date ? '' : raw.payout_date,
      phone: !raw.phone ? '' : raw.phone,
      principal: !raw.principal ? 0 : raw.principal,
      repaymentId: !raw.repaymentId ? 0 : raw.repaymentId,
      repaymentTransactionId: !raw.repaymentTransactionId ? '' : raw.repaymentTransactionId,
      repaymentUUID: !raw.repaymentUUID ? '' : raw.repaymentUUID,
      repayment_tds_rate: !raw.repayment_tds_rate ? 0 : raw.repayment_tds_rate,
      series_name: !raw.series_name? '' : raw.series_name,
      status: !raw.status ? 0 : raw.status,
      total_number_of_ncd: !raw.total_number_of_ncd ? 0 : raw.total_number_of_ncd,
      type: !raw.type  ? '' : raw.type,
    };
  };
  /**
   * @description This function is to get client list of ifa
   * @returns it returns user overall client list data.
   */
  async getPastRepayment(pan_number: string, series_name: string, page: number, pagesize: number, isDownload: boolean): Promise<Types.GetRepaymentResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/getRepayments", {
        queryStringParameters: {
          status: 1,
          pan_number,
          series_name,
          page,
          pagesize,
          isDownload
        },
      });
      const rawData = response.data;
      const data = rawData?.data || [];
      if (isDownload == true) {
        const convertedData: RepaymentSnapshot[] = data.map(
          this.dataConverter
        );
        return { kind: "ok", repayment: convertedData, total: rawData.total,series:response.data.serie_names };
      }
      const convertedData: RepaymentSnapshot[] = data.length!=0?JSON.parse(data).map(
        this.dataConverter
      ):[];
      return { kind: "ok", repayment: convertedData, total: rawData.total,series:response.data.serie_names };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  async getUpcomingRepayment(pan_number: string, series_name: string, page: number, pagesize: number, isDownload: boolean): Promise<Types.GetRepaymentResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/getRepayments", {
        queryStringParameters: {
          status: 0,
          pan_number,
          series_name,
          page,
          pagesize,
          isDownload
        },
      });
      const rawData = response.data;
      const data = rawData?.data || [];
      if (isDownload == true) {
        const convertedData: RepaymentSnapshot[] = data.map(
          this.dataConverter
        );
        return { kind: "ok", repayment: convertedData, total: response.data.total,series:response.data.serie_names};
      }
      const convertedData: RepaymentSnapshot[] = data.length!=0?JSON.parse(data).map(
        this.dataConverter
      ):[];
      console.log(response.data);
      return { kind: "ok", repayment: convertedData, total: response.data.total,series:response.data.series_names };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
