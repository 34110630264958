import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const OpenPoolModel = types
  .model("OpenPool")
  .props({
    id: types.optional(types.string, ""),
    pool_title: types.optional(types.string, ""),
    risk_rate: types.optional(types.string, ""),
    badge_title: types.optional(types.string, ""),
    tenure: types.optional(types.number, 0),
    tenure_type: types.optional(types.string, ""),
    interest_rate: types.optional(types.string, "0"),
    minimum_investment: types.optional(types.string, ""),
    start_date: types.optional(types.string, ""),
    end_date: types.optional(types.string, ""),
    demand_amount: types.optional(types.string, ""),
    totle_investment: types.optional(types.string, ""),
    is_shielded: types.optional(types.number, 0),
    description: types.optional(types.string, ""),
    logo_color: types.optional(types.string, ""),
    badge_color: types.optional(types.string, ""),
    risk_color: types.optional(types.string, ""),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type OpenPoolType = Instance<typeof OpenPoolModel>;
export interface OpenPool extends OpenPoolType {}

type OpenPoolSnapshotType = SnapshotOut<typeof OpenPoolModel>;
export interface OpenPoolSnapshot extends OpenPoolSnapshotType {}
