import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  InvestmentHistory,
  InvestmentHistoryModel,
  InvestmentHistorySnapshot,
} from "../investment-history/investment-history";
import { InvestmentHistory_API } from "../../../services/api";

// make the instance of api
const api = new InvestmentHistory_API();

export const InvestmentHistoryStoreModel = types
  .model("InvestmentHistoryStore")
  .props({
    investmenthistory: types.optional(types.array(InvestmentHistoryModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is for save the client list
     * @param InvestmentHistorySnapshot
     */
    saveInvestmentHistory: (InvestmentHistorySnapshot: InvestmentHistorySnapshot[]) => {
      const investmentHistoryModel: InvestmentHistory[] = InvestmentHistorySnapshot.map((c) =>
        InvestmentHistoryModel.create(c)
      );
      self.investmenthistory.replace(investmentHistoryModel);
    },
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getInvestmentHistory: flow(function* (pan_number, series_name, page, pagesize, isDownload) {
      const result = yield api.getInvestmentHistory(pan_number, series_name, page, pagesize, isDownload);
      if (result.kind === "ok") {
        self.saveInvestmentHistory(result.investmenthistory);
        return { kind: "ok", data: result.investmenthistory, series: result.series || '', total: result.total };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
    getInvestmentDocument: flow(function* (data) {
      const result = yield api.getInvestmentDocument(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.data };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }))
  .actions((self) => ({
    /**
     * @description this function is for get the client list
     */
     getInvestmentDocument: flow(function* (data) {
      const result = yield api.getInvestmentDocument(data);
      if (result.kind === "ok") {
        return { kind: "ok", data: result.data };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type InvestmentHistoryStoreType = Instance<typeof InvestmentHistoryStoreModel>;
export interface InvestmentHistoryStore extends InvestmentHistoryStoreType { }

type InvestmentHistoryStoreSnapshotType = SnapshotOut<typeof InvestmentHistoryStoreModel>;
export interface InvestmentHistoryStoreSnapshot extends InvestmentHistoryStoreSnapshotType { }
