import React, { useEffect, useState } from "react";
import "./profile.css";
import {
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Stack,
  Button,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useStores } from "../../models";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";
import { errorToast, convertAmount, successToast } from "../../utils/helper";
import { ContentLoadingLoader, SampleToast } from "../../components";
import awsConfig from "../../constant/aws-exports";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Profile = observer(function InvestorProfile(props) {
  const navigate = useHistory();
  const [investorProfileData, setInvestorProfileData] = useState({});
  const [expanded, setExpanded] = React.useState("panel1");
  const [render, setRender] = useState(true);
  const rootStore = useStores();
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [bank, setBank] = useState({});

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    props.showCommonSidebar(true);
    let checkLogin = async () => {
      try {
        const pan_number = props?.location?.state?.pan_number || null;
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        fetchInvestorProfile(pan_number);
      } catch (err) {
        console.log("investor profile use effect catch", err);
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, [props]);

  // fetch the client list
  const fetchInvestorProfile = (pan_number) => {
    rootStore.investorProfile.getInvestorProfile(pan_number).then((res) => {
      if (res.kind === "ok") {
        setInvestorProfileData(res.profile[0]);
        if (res.profile[0].banks && res.profile[0].banks.length) {
          const bankDetail = JSON.parse(res.profile[0].banks);

          setBank({
            ifsc_code: bankDetail[0].ifsc_code || "",
            branch_name: bankDetail[0].branch_name || "",
            account_number: bankDetail[0].account_number || "",
            bankDetailName: bankDetail[0].bankDetailName || "",
            bankDetailUUID: bankDetail[0].bankDetailUUID || "",
            bankDetailAddress: bankDetail[0].bankDetailAddress || "",
            bank_with_account_number:
              bankDetail[0].bank_with_account_number || "",
          });
        }
        setRender(true);
      } else {
        setRender(true);
        errorToast(res.error);
      }
    });
  };

  const handleDownload = async (key) => {
    console.log(key);
    const stage = process.env.NODE_ENV === "production" ? "prod" : "me";
    rootStore.investmentHistory
      .getInvestmentDocument({
        bucketName: awsConfig[stage].AWSS3.bucket,
        fileName: key,
      })
      .then((res) => {
        if (res.kind === "ok") {
          const blob = new Blob([Buffer.from(res.data.BodyString, "base64")], {
            type: res.data.ContentType,
          });
          const blobUrl = window.URL.createObjectURL(blob);
          window.open(blobUrl, "_blank", "noopener,noreferrer");
          setRender(true);
        } else {
          setRender(true);
          errorToast(res.error);
        }
      });
  };

  // handle page change function
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="profile_div_container">
          {/* table */}
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="profile_div_tablecontainer">
                {/* header */}
                <div className="profile_header_div">
                  {/* text */}
                  <h1 className="profile_header_div_title">Profile</h1>
                </div>
              </div>
            )}
          </div>
          {/* Profile Detail */}
          <div className="profile_div_tablecontainer">
            {/* buttons */}
            <div className="action_buttons">
              <Stack spacing={100} direction="row">
                <Button
                  variant="outlined"
                  className="button"
                  onClick={() => {
                    navigate.goBack();
                    // navigate.push("/investor");
                  }}
                >
                  Back
                </Button>
                {/* <Button
                  variant="contained"
                  className="button"
                  onClick={() => {
                    navigate.push("#");
                  }}
                >
                  Edit
                </Button> */}
              </Stack>
            </div>
            {/* slider */}
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Basic Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Full Name
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.name === "nan"
                      ? ""
                      : investorProfileData.name
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Father/Trustee Name
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.trustee_name === "nan"
                      ? "-"
                      : investorProfileData.trustee_name
                    : "-"}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Date of Birth
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.dob_format === "nan"
                      ? "-"
                      : investorProfileData.dob_format
                    : "-"}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  E-mail id
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.email === "nan"
                      ? ""
                      : investorProfileData.email
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Address
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.address === "nan"
                      ? ""
                      : investorProfileData.address
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Category
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.category === 0
                      ? "Non Individual"
                      : "Individual"
                    : 0}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Pincode
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.pincode === "nan"
                      ? "-"
                      : investorProfileData.pincode
                    : "-"}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Partner Name
                </Typography>
                <Typography
                  variant="button"
                  style={{ fontWeight: "bolder" }}
                  gutterBottom
                >
                  {investorProfileData
                    ? investorProfileData.partnerName === "nan"
                      ? "-"
                      : investorProfileData.partnerName
                    : "-"}
                </Typography>

                <br />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Documents</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    display="disable"
                    style={{ color: "rgba(0, 0, 0, 0.65)", width: 150 }}
                    gutterBottom
                  >
                    Pan Number
                  </Typography>
                  {/* <div className="document_link">
                    <p
                      className="investmentHistory_client_name"
                      style={{
                        cursor:
                          investorProfileData &&
                          investorProfileData.pan_image &&
                          investorProfileData.pan_image !== ""
                            ? "pointer"
                            : "default",
                        opacity:
                          investorProfileData.pan_image &&
                          investorProfileData.pan_image !== ""
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        investorProfileData.pan_image &&
                        investorProfileData.pan_image !== ""
                          ? handleDownload(investorProfileData.pan_image)
                          : null
                      }
                    >
                      View Document
                    </p>
                  </div> */}
                </div>
                <Typography
                  variant="button"
                  gutterBottom
                  style={{ fontWeight: "bolder" }}
                >
                  {investorProfileData
                    ? investorProfileData.pan_number === "nan"
                      ? "-"
                      : investorProfileData.pan_number
                    : "-"}
                </Typography>

                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    display="disable"
                    style={{ color: "rgba(0, 0, 0, 0.65)", width: 150 }}
                    gutterBottom
                  >
                    Demat Id
                  </Typography>
                  <div className="document_link">
                    <p
                      style={{
                        fontSize:'12px',
                        cursor:
                          investorProfileData &&
                          investorProfileData.demat_image &&
                          investorProfileData.demat_image !== ""
                            ? "pointer"
                            : "default",
                        opacity:
                          investorProfileData.demat_image &&
                          investorProfileData.demat_image !== ""
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        investorProfileData.demat_image &&
                        investorProfileData.demat_image !== ""
                          ? handleDownload(investorProfileData.demat_image)
                          : null
                      }
                    >
                      View Document
                    </p>
                  </div> 
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    display="disable"
                    style={{ color: "rgba(0, 0, 0, 0.65)", width: 150 }}
                    gutterBottom
                  >
                    Address Image (Front)
                  </Typography>
                  <div className="document_link">
                    <p
                      style={{
                        fontSize:'12px',
                        cursor:
                          investorProfileData &&
                          investorProfileData.address_front_image &&
                          investorProfileData.address_front_image !== ""
                            ? "pointer"
                            : "default",
                        opacity:
                          investorProfileData.address_front_image &&
                          investorProfileData.address_front_image !== ""
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        investorProfileData.address_front_image &&
                        investorProfileData.address_front_image !== ""
                          ? handleDownload(
                              investorProfileData.address_front_image
                            )
                          : null
                      }
                    >
                      View Document
                    </p>
                  </div> 
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    display="disable"
                    style={{ color: "rgba(0, 0, 0, 0.65)", width: 150 }}
                    gutterBottom
                  >
                    Address Image (Back)
                  </Typography>
                  <div className="document_link">
                    <p
                      style={{
                        fontSize:'12px',
                        cursor:
                          investorProfileData &&
                          investorProfileData.address_back_image &&
                          investorProfileData.address_back_image !== ""
                            ? "pointer"
                            : "default",
                        opacity:
                          investorProfileData.address_back_image &&
                          investorProfileData.address_back_image !== ""
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        investorProfileData.address_back_image &&
                        investorProfileData.address_back_image !== ""
                          ? handleDownload(
                              investorProfileData.address_back_image
                            )
                          : null
                      }
                    >
                      View Document
                    </p>
                  </div> 
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    display="disable"
                    style={{ color: "rgba(0, 0, 0, 0.65)", width: 150 }}
                    gutterBottom
                  >
                    Certificate Of Incorporation
                  </Typography>
                  <div className="document_link">
                    <p
                      style={{
                        fontSize:'12px',
                        cursor:
                          investorProfileData &&
                          investorProfileData.certificate_of_incorporation &&
                          investorProfileData.certificate_of_incorporation !==
                            ""
                            ? "pointer"
                            : "default",
                        opacity:
                          investorProfileData.certificate_of_incorporation &&
                          investorProfileData.certificate_of_incorporation !==
                            ""
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        investorProfileData.certificate_of_incorporation &&
                        investorProfileData.certificate_of_incorporation !== ""
                          ? handleDownload(
                              investorProfileData.certificate_of_incorporation
                            )
                          : null
                      }
                    >
                      View Document
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    display="disable"
                    style={{ color: "rgba(0, 0, 0, 0.65)", width: 150 }}
                    gutterBottom
                  >
                    Board Resolution Image
                  </Typography>
                  <div className="document_link">
                    <p
                      style={{
                        fontSize:'12px',
                        cursor:
                          investorProfileData &&
                          investorProfileData.board_resolution_image &&
                          investorProfileData.board_resolution_image !== ""
                            ? "pointer"
                            : "default",
                        opacity:
                          investorProfileData.board_resolution_image &&
                          investorProfileData.board_resolution_image !== ""
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        investorProfileData.board_resolution_image &&
                        investorProfileData.board_resolution_image !== ""
                          ? handleDownload(
                              investorProfileData.board_resolution_image
                            )
                          : null
                      }
                    >
                      View Document
                    </p>
                  </div> 
                </div>
                <br />
                <br />
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body2"
                    display="disable"
                    style={{ color: "rgba(0, 0, 0, 0.65)", width: 150 }}
                    gutterBottom
                  >
                    Other
                  </Typography>
                  <div className="document_link">
                    <p
                      style={{
                        fontSize:'12px',
                        cursor:
                          investorProfileData &&
                          investorProfileData.other &&
                          investorProfileData.other !== ""
                            ? "pointer"
                            : "default",
                        opacity:
                          investorProfileData.other &&
                          investorProfileData.other !== ""
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        investorProfileData.other &&
                        investorProfileData.other !== ""
                          ? handleDownload(investorProfileData.other)
                          : null
                      }
                    >
                      View Document
                    </p>
                  </div> 
                </div>
                <br />
                <br />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Bank Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl fullWidth sx={{ minWidth: 100 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Select Bank
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bank.bankDetailName}
                    label="Bank Selection"
                    onChange={(e) => {
                      console.log(e);
                      setBank(
                        JSON.parse(investorProfileData.banks)[e.target.value]
                      );
                    }}
                  >
                    {investorProfileData &&
                    investorProfileData.banks &&
                    investorProfileData.banks.length
                      ? JSON.parse(investorProfileData.banks).map(
                          (data, index) => (
                            <MenuItem value={index} key={index}>
                              {data.bankDetailName}
                            </MenuItem>
                          )
                        )
                      : null}
                  </Select>
                </FormControl>

                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Account Number
                </Typography>
                <Typography variant="button" gutterBottom>
                  {bank
                    ? bank.account_number === "nan"
                      ? ""
                      : bank.account_number
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Bank Name
                </Typography>
                <Typography variant="button" gutterBottom>
                  {bank
                    ? bank.bankDetailName === "nan"
                      ? ""
                      : bank.bankDetailName
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Branch Name
                </Typography>
                <Typography variant="button" gutterBottom>
                  {bank
                    ? bank.branch_name === "nan"
                      ? ""
                      : bank.branch_name
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  IFSC Code
                </Typography>
                <Typography variant="button" gutterBottom>
                  {bank ? (bank.ifsc_code === "nan" ? "" : bank.ifsc_code) : ""}
                </Typography>
                <br />
                <br />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <Typography>Nominee Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Full Name
                </Typography>
                <Typography variant="button" gutterBottom>
                  {investorProfileData
                    ? investorProfileData.nomineeName === "nan"
                      ? ""
                      : investorProfileData.nomineeName
                    : ""}
                </Typography>
                <br />
                <br />
                {/* <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Mobile Number
                </Typography>
                <Typography variant="button" gutterBottom>
                  {investorProfileData
                    ? investorProfileData.nomineePhone === "nan"
                      ? ""
                      : investorProfileData.nomineePhone
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  E-mail
                </Typography>
                <Typography variant="button" gutterBottom>
                  {investorProfileData
                    ? investorProfileData.nomineeEmail === "nan"
                      ? ""
                      : investorProfileData.nomineeEmail
                    : ""}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Pan Number
                </Typography>
                <Typography variant="button" gutterBottom>
                  {investorProfileData
                    ? investorProfileData.nomineePanNumber === "nan"
                      ? ""
                      : investorProfileData.nomineePanNumber
                    : ""}
                </Typography>
                <br />
                <br /> */}
                <Typography
                  variant="body2"
                  display="disable"
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  gutterBottom
                >
                  Relationship with Client
                </Typography>
                <Typography variant="button" gutterBottom>
                  {investorProfileData
                    ? investorProfileData.relationship_with_user === "nan"
                      ? ""
                      : investorProfileData.relationship_with_user
                    : ""}
                </Typography>
                <br />
                <br />
              </AccordionDetails>
            </Accordion>
          </div>
          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
});

export default Profile;
