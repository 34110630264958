import { API } from "aws-amplify";
import { InvestorSnapshot } from "../../../models";
import { InvestorProfileSnapshot } from "../../../models/InvestorProfile/investorProfile/investorProfile";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class Investor_API {
  /**
     * @description This function is for converting raw data into the actual data
     * @param raw
     * @returns it return the actual data that can be displayed
     */
  dataConverter = (raw: any) => {
    return {
      uuid: raw.uuid,
      name: raw.name === undefined ? "" : raw.name,
      pan_number: raw.pan_number === undefined ? "" : raw.pan_number,
      phone: raw.phone === undefined ? "" : raw.phone,
      email: raw.email === undefined ? "" : raw.email,
    };
  };
  dataConverterProfile = (raw: any) => {
    return {
      uuid: raw.uuid,
      pan_number: raw.pan_number === undefined ? "" : raw.pan_number,
      name: raw.name === undefined ? "" : raw.name,
      phone: raw.phone === undefined ? "" : raw.phone,
      email: raw.email === undefined ? "" : raw.email,
      address: raw.address === undefined ? "" : raw.address,
      clientUUID: raw.clientUUID === undefined ? "" : raw.clientUUID,
      id: raw.id === undefined ? 0 : raw.id,
      pincode: raw.pincode === undefined ? "" : raw.pincode,
      category: raw.category === undefined ? 0 : raw.category,
      dob: raw.dob === undefined ? "" : raw.dob,
      dob_format: raw.dob_format === undefined ? "" : raw.dob_format,
      mode: raw.mode === undefined ? 0 : raw.mode,
      demat_id: raw.demat_id === undefined ? "" : raw.demat_id,
      partner_id: raw.partner_id === undefined ? "" : raw.partner_id,
      trustee_name: raw.trustee_name === undefined ? "" : raw.trustee_name,
      address_front_image: raw.address_front_image === undefined ? null : raw.address_front_image,
      address_back_image: raw.address_back_image === undefined ? null : raw.address_back_image,
      board_resolution_image: raw.board_resolution_image === undefined ? null : raw.board_resolution_image,
      certificate_of_incorporation: raw.certificate_of_incorporation === undefined ? null : raw.certificate_of_incorporation,
      demat_image: raw.demat_image === undefined ? null : raw.demat_image,
      other: raw.other === undefined ? null : raw.other,
      pan_image: raw.pan_image === undefined ? null : raw.pan_image,
      documentUUID: raw.documentUUID === undefined ? null : raw.documentUUID,
      nomineeId: raw.nomineeId === undefined ? 0 : raw.nomineeId,
      bankDetailUUID: raw.bankDetailUUID === undefined ? "" : raw.bankDetailUUID,
      account_number: raw.account_number === undefined ? "" : raw.account_number,
      branch_name: raw.branch_name === undefined ? "" : raw.branch_name,
      ifsc_code: raw.ifsc_code === undefined ? "" : raw.ifsc_code,
      bankDetailAddress: raw.bankDetailAddress === undefined ? "" : raw.bankDetailAddress,
      bankDetailName: raw.bankDetailName === undefined ? "" : raw.bankDetailName,
      nomineePanNumber: raw.nomineePanNumber === undefined ? "" : raw.nomineePanNumber,
      nomineeName: raw.nomineeName === undefined ? "" : raw.nomineeName,
      nomineePhone: raw.nomineePhone === undefined ? "" : raw.nomineePhone,
      nomineeEmail: raw.nomineeEmail === undefined ? "" : raw.nomineeEmail,
      relationship_with_user: raw.relationship_with_user === undefined ? "" : raw.relationship_with_user,
      nomineeUUID: raw.nomineeUUID === undefined ? "" : raw.nomineeUUID,
      partnerName: raw.partnerName === undefined ? "" : raw.partnerName,
      banks: raw.banks === undefined ? "" : raw.banks,
      bank_with_account_number: raw.bank_with_account_number === undefined ? "" : raw.bank_with_account_number
    };
  };
  /**
   * @description This function is to get client list of ifa
   * @returns it returns user overall client list data.
   */
  async getInvestor(pan_number: string, page: number, pagesize: number, isDownload: boolean): Promise<Types.GetInvestorResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/clients", {
        queryStringParameters: {
          pan_number,
          page,
          pagesize,
          isDownload
        },
      });
      const data = response.data?.data || [];
      //const data = JSON.parse(rawData?.data || []);
      
      if (isDownload == true) {
        const convertedData: InvestorSnapshot[] = data.map(
          this.dataConverter
        );
        return { kind: "ok", investor: convertedData, total: response.data.total };
      }
      
      const convertedData: InvestorSnapshot[] = data.length!=0?JSON.parse(data).map(
        this.dataConverter
      ):[];
      console.log(convertedData);
      return { kind: "ok", investor: convertedData, total: response.data.total };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  async getInvestorProfile(pan_number: string): Promise<Types.GetInvestorProfileResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/clientProfile", {
        queryStringParameters: {
          pan_number,
        },
      });
      const rawData = response.data;
      // const data = Object.assign(JSON.parse(JSON.stringify(rawData || [])));
      const convertedData: InvestorProfileSnapshot[] = rawData.map(
        this.dataConverterProfile
      );
      return { kind: "ok", profile: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
