import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import { User_Data_Model } from "../user-data/user-data";
import { User_API } from "../../../services/api";

// make the instance of api
const api = new User_API();

export const UserDataStoreModel = types
  .model("UserDataStore")
  .props({
    userdata: types.optional(types.array(User_Data_Model), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    /**
     * @description this function is to get the logged in users overall data
     * @param number
     * @returns it return the user overall data of the current user
     */
    checkUserExistence: flow(function* (phone: string) {

      const result = yield api.checkUserExistence(phone);

      if (result.kind === "ok") {
        self.userdata.clear();
        localStorage.setItem("name", result.data.name);
        localStorage.setItem("email", result.data.email);
        localStorage.setItem("mobile", result.data.mobile || result.data.phone);
        return { kind: "ok", data: result.data };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
    updateUserUUID: flow(function* (data: any) {

      const result = yield api.updateUserUUID(data);

      if (result.kind === "ok") {
        return { kind: "ok", data: result.data };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
    getUserDetail: flow(function* () {

      const result = yield api.getUserDetail();

      if (result.kind === "ok") {
        self.userdata.clear();
        localStorage.setItem("name", result.data.name);
        localStorage.setItem("email", result.data.email);
        localStorage.setItem("mobile", result.data.mobile || result.data.phone);
        return { kind: "ok", data: result.data };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),

  }));

type UserDataStoreType = Instance<typeof UserDataStoreModel>;
export interface UserDataStore extends UserDataStoreType {}

type UserDataStoreSnapshotType = SnapshotOut<typeof UserDataStoreModel>;
export interface UserDataStoreSnapshot extends UserDataStoreSnapshotType {}
