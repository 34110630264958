import React, { useEffect, useState } from "react";
import "./auth.css";
import { Auth } from "aws-amplify";
import Login from "./Login/login";
import AuthIcon from "../../assets/images/auth.svg";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";

const AuthPage = observer(function AuthPage(props) {
  const [isLogin, setIsLogin] = useState();
  const [render, setRender] = useState(false);
  const [mobile, setMobile] = useState("");
  const [showPage, setShowPage] = useState("Login");

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(false);
    props.showCommonSidebar(false);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
      } catch {
        setRender(true);
        setIsLogin(false);
      }
    };
    return checkLogin();
  }, [props]);

  // if user is login then redirect to investmentHistory page
  if (isLogin === true) {
    return <Redirect to="/investor" />;
  }

  return (
    <div>
      {render === false ? (
        <div />
      ) : (
        <div className="auth_main_container">
          {/* header */}
          <header className="auth_header">
            <img
              src="arthmate.png"
              alt="logo"
              className="auth_logo_image"
              onClick={() => window.open("https://www.arthmate.com/", "_blank")}
            />
          </header>

          {/* auth rest container */}
          <div className="auth_rest_container">
            {/* image div */}
            <div className="auth_image_container">
              {/* title */}
              <h1 className="auth_main_title">NCD Dashboard</h1>
              {/* image */}
              <img src={AuthIcon} alt="auth_icon" className="auth_image" />
            </div>

            {/* form div */}
            <div className="auth_form_container">
                <Login
                  changeMobile={(mobile, page) => {
                    setMobile(mobile);
                    setShowPage(page);
                  }}
                />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default AuthPage;
