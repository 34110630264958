import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const User_Data_Model = types
  .model("UserDataModel")
  .props({
    uuid: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    mobile: types.optional(types.string, ""),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

type User_Data_Model_DataType = Instance<typeof User_Data_Model>;
export interface User_Data extends User_Data_Model_DataType {}

type User_Data_Model_SnapShotType = SnapshotOut<typeof User_Data_Model>;
export interface User_Data_SnapShot extends User_Data_Model_SnapShotType {}
