const awsConfig = {
  me: {
    Auth: {
      userPoolId: "ap-south-1_FzcK8rdYX",
      userPoolWebClientId: "2ghs195q949gncah65tbjqcm62",
      identityPoolId: "ap-south-1:df32ad87-f50a-4f37-90e6-db5b0c7ecbe8",
      region: "ap-south-1",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "baseurl",
          endpoint: "https://api-ncd-me.arthmate.in",
          region: "ap-south-1",
        },
        {
          name: "paytmBaseURL",
          endpoint: "https://securegw-stage.paytm.in",
        },
      ],
    },
    AWSS3: {
      bucket: "arthmate-ncd",
      region: "ap-south-1",
    },
  },
  dev: {
    Auth: {
      userPoolId: "ap-south-1_FzcK8rdYX",
      userPoolWebClientId: "2ghs195q949gncah65tbjqcm62",
      identityPoolId: "ap-south-1:df32ad87-f50a-4f37-90e6-db5b0c7ecbe8",
      region: "ap-south-1",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "baseurl",
          endpoint: "https://backdev.arthmate.in",
          region: "ap-south-1",
        },
        {
          name: "paytmBaseURL",
          endpoint: "https://securegw-stage.paytm.in",
        },
      ],
    },
    AWSS3: {
      bucket: "arthmate-ncd",
      region: "ap-south-1",
    },
  },
  prod: {
    Auth: {
      userPoolId: "ap-south-1_XCxZfeKve",
      userPoolWebClientId: "44h2gdb9kv56f6s1t3gm2et57e",
      identityPoolId: "ap-south-1:b155625d-ec52-45a2-a36e-851cc5c8ac8b",
      region: "ap-south-1",
      authenticationFlowType: "CUSTOM_AUTH",
    },
    API: {
      endpoints: [
        {
          name: "baseurl",
          // endpoint: "https://backprod.arthmate.in",
          endpoint: "https://api-ncd-prod.arthmate.in",
          region: "ap-south-1",
        },
        {
          name: "paytmBaseURL",
          endpoint: "https://securegw.paytm.in",
        },
      ],
    },
    AWSS3: {
      bucket: "arthmate-ncd",
      region: "ap-south-1",
    },
  },
};

export default awsConfig;
