import React, { useEffect, useState } from "react";
import "./commission_investment.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import { useStores } from "../../../models";
import { useHistory, Redirect } from "react-router-dom";
import { Auth, Storage } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { observer } from "mobx-react-lite";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  errorToast,
  convertAmount,
  successToast,
  convert_json_array_to_csv,
  convertIntoINR,
} from "../../../utils/helper";
import {
  ContentLoadingLoader,
  FullScreenLoader,
  SampleToast,
} from "../../../components";
import awsConfig from "../../../constant/aws-exports";
import Modal from "@mui/material/Modal";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
  height: "auto",
  minWidth: "900px",
  overflowY: "scroll",
};

const tableHeader = {
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 15,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 13,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const pageSize = 8;

const CommissionInvestment = (props) => {
  const [pagination, setPagination] = useState({
    current_page:0
  });
  const [commissionData, setCommissionData] = useState([]);
  const [fullLoader, setFullLoader] = useState(false);
  const [totalCommissions, setTotalCommissions] = useState(0);
  const [render, setRender] = useState(true);
  const rootStore = useStores();
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [selectedNcd, setSelectedNcd] = useState();
  const [openInvestmentCommission, setOpenInvestmentCommission] =
    useState(false);

  useEffect(() => {
    if(props.open && props.ncd_id){
        getCommissionData(pageSize,0,props.ncd_id);
    }
  }, [props.open,props.ncd_id]);


  // get aum data
  const getCommissionData = async (pagesize, page, ncd) => {
    setFullLoader(true);
    rootStore.commission
      .getCommissionInvestment(ncd, page, pagesize, isDownload)
      .then((res) => {
        if (res.kind === "ok") {
          setCommissionData(res.data);
          console.log("Page",res)
          setPagination({
            current_page: parseInt((res.page)-1),
            count: res.total
          });
          setFullLoader(false);
        } else {
          setRender(true);
          errorToast(res.error);
          setFullLoader(false);
        }
      });
  };

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflow: "scroll",
        }}
      >
        <div className="commission_investment-model-div">
          {/* header */}
          <div className="commission_investment-model-header-div">
            <h1 className="commission_investment-model-header-text">
              Breakdown of Investment Commissions
            </h1>
          </div>
          {/* table content */}
          <TableContainer style={tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={tableHeader}>
                    Investor Name
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Invested Amount (₹)
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Commission Rate (%)
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Calculated Commission (₹)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {commissionData.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                    }}
                  >
                    <TableCell style={tableRow} align="left">
                      {row.name}
                    </TableCell>
                    <TableCell style={tableRow} align="left">
                      {convertIntoINR(row.invested_amount)}
                    </TableCell>
                    <TableCell style={tableRow} align="left">
                      {row.commission_rate}
                    </TableCell>
                    <TableCell style={tableRow} align="left">
                      {convertIntoINR(row.calculated_commission)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* if no data available */}
            {commissionData.length === 0 ? (
              <div className="no_row_found_div">
                <FindInPageIcon
                  style={{
                    fontSize: "100px",
                    color: "#555555",
                  }}
                />
                <p className="no_row_found">No row found</p>
              </div>
            ) : (
              <div />
            )}
          </TableContainer>
          {/* pagination */}
          <div className="commission_investment_pagination_div">
            {/* previous */}
            <ArrowBackIosIcon
              style={{
                fontSize: "14px",
                color: pagination.current_page === 0 ? "#b8b8b8" : "#3d3d3d",
                marginRight: "20px",
                cursor: pagination.current_page === 0 ? "default" : "pointer",
              }}
              onClick={() => {
                if (pagination.current_page !== 0) {
                  getCommissionData(pageSize, pagination?.current_page - 1,props.ncd_id);
                }
              }}
            />
            {console.log("Calcualte",(pagination?.current_page+1)*pageSize)}
            {/* next */}
            <ArrowForwardIosIcon
              style={{
                fontSize: "14px",
                color: pagination.count > ((pagination?.current_page+1)*pageSize) ? "#3d3d3d" : "#b8b8b8",
                marginRight: "30px",
                cursor: pagination.count  > ((pagination?.current_page+1)*pageSize) ? "pointer" : "default",
              }}
              onClick={() => {
                if (pagination.count > ((pagination?.current_page+1)*pageSize)) {
                  getCommissionData(pageSize, pagination?.current_page + 1,props.ncd_id);
                }
              }}
            />
          </div>
          {/* footer */}
          <div className="commission_investment-model-footer-div">
            <button
              className="commission_investment-model-footer-button"
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      {fullLoader ? (
        <FullScreenLoader open={fullLoader ? true : false} />
      ) : (
        <div />
      )}
      {/* toast container */}
      <SampleToast />
    </React.Fragment>
  );
};

export default CommissionInvestment;
