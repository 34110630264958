import { API } from "aws-amplify";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */

export class User_API {
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  convertUserData = (raw: any) => {
    return {
      uuid: raw.uuid === undefined ? "" : raw.uuid,
      mobile: raw.mobile === undefined ? "" : raw.mobile,
      name: raw.name === undefined ? "" : raw.name,
      email: raw.email === undefined ? "" : raw.email,
      kyc_status: raw.kyc_status === undefined ? 0 : raw.kyc_status,
    };
  };

  /**
   * @description This function is to get logged in user data
   * @param number
   * @returns it returns user overall data.
   */
  async checkUserExistence(mobile: string): Promise<Types.GetUserSummary> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/public-api/partner/exists",
      {
        queryStringParameters: {
          mobile
        },
        headers: {
          "Content-type": "application/json",
        },
      });

      return { kind: "ok", data: response.message || response.data || {} };
    } catch (err: any) {

      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 404 || err.response.data.code===422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  async updateUserUUID(data: any): Promise<Types.GetUserSummary> {
    const params = {
      body: data
    };
    try {
      const response: any = await API.post("baseurl", "/partner/update/uuid", params);
      return { kind: "ok", data: response.message || response.data || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  async getUserDetail(): Promise<Types.GetUserSummary> {
    try {
      const response: any = await API.get("baseurl", "/partner/get", {});
      return { kind: "ok", data: response.message || response.data || {} };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get logged in user overall summary
   * @param number
   * @returns it returns user overall summary.
   */
  async getUserSummary(): Promise<Types.GetUserSummary> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/summary", {});
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
