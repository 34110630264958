import { API } from "aws-amplify";
import { CommissionSnapshot } from "../../../models";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class Commission_API {
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  dataConverter = (raw: any,index:any) => {
    return {
      "S.No": index+1,
      uuid: raw.uuid,
      series_name: raw.series_name === undefined ? "" : raw.series_name,
      commission_rate: raw.commission_rate === undefined ? 0 : raw.commission_rate,
      commission_accrued: raw.commission_accrued === undefined ? 0 : raw.commission_accrued,
      ncd_id: raw.ncd_id=== undefined?'': raw.ncd_id
    };
  };

  investmentDataConverter = (raw: any,index:any) => {
    return {
      "S.No": index+1,
      uuid: raw.uuid,
      name: raw.name === undefined ? "" : raw.name,
      invested_amount: raw.invested_amount === undefined ? "" : raw.invested_amount,
      commission_rate: raw.commission_rate === undefined ? 0 : raw.commission_rate,
      calculated_commission: raw.calculated_commission===undefined?'':raw.calculated_commission,
      ncd_id: raw.ncd_id===undefined?'':raw.ncd_id
    };
  };

  /**
   * @description This function is to get client list of ifa
   * @returns it returns user overall client list data.
   */
  async getCommission(ncd:string,page:number,pageSize:number,isDownload:boolean): Promise<Types.GetCommissionResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/commissions", {
        queryStringParameters: {
          ncd,
          page,
          page_size:pageSize,
          is_download:isDownload
        },
      });

      console.log(response)
      const data = response.data?.data || [];
      //const data = JSON.parse(rawData?.data || []);

      const convertedData: CommissionSnapshot[] =data.map((item:any,index:number)=>{
        return this.dataConverter(item,index);
      }
      );

      return { kind: "ok", data: convertedData, total: response.data.total, ncd_series: response.data.ncd_series };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get client list of ifa
   * @returns it returns user overall client list data.
   */
   async getCommissionInvestment(ncd:string,page:number,pageSize:number,isDownload:boolean): Promise<Types.GetCommissionInvestmentResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/commissions/investments", {
        queryStringParameters: {
          ncd,
          page,
          page_size:pageSize,
          is_download:isDownload
        },
      });

      console.log(response)
      const data = response.data?.data || [];
      //const data = JSON.parse(rawData?.data || []);

      const convertedData: any =data.map((item:any,index:number)=>{
        return this.investmentDataConverter(item,index);
      }
      )

      return { kind: "ok", data: convertedData, total: response.data.total, page: response.data.page };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
