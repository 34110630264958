import { types } from "mobx-state-tree";
import { OpenPoolStoreModel } from "../Pool/open-pool-store/open-pool-store";
import { InvestmentHistoryStoreModel } from "../InvestmentHistory/investment-history-store/investment-history-store";
import { UserDataStoreModel } from "../User/user-data-store/user-data-store";
import { GetPoolByIdStoreModel } from "../PoolById/get-pool-by-id-store/get-pool-by-id-store";
import { InvestorStoreModel } from "../Investor/investor-store/investor-store";
import { InvestorProfileStoreModel } from "../InvestorProfile/investorProfile-store/investorProfile-store";
import { RepaymentStoreModel } from "../Repayment/repayment-store/repayment-store";
import { OverviewStoreModel } from "../Overview/overview-store/overview-store";
import { CommissionStoreModel } from "../Commissions/commission-store/commission-store";

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
  userData: types.optional(UserDataStoreModel, {}),
  investmentHistory: types.optional(InvestmentHistoryStoreModel, {}),
  investor: types.optional(InvestorStoreModel, {}),
  investorProfile: types.optional(InvestorProfileStoreModel, {}),
  repayment: types.optional(RepaymentStoreModel, {}),
  openPool: types.optional(OpenPoolStoreModel, {}),
  getPoolById: types.optional(GetPoolByIdStoreModel, {}),
  overview: types.optional(OverviewStoreModel,{}),
  commission: types.optional(CommissionStoreModel,{})
});

export const useStores = () => RootStoreModel.create();
