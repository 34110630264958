import React, { useEffect, useState } from "react";
import "./repayment.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import { useStores } from "../../models";
import { useHistory, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth, Storage } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {
  errorToast,
  convertAmount,
  successToast,
  convert_json_array_to_csv,
} from "../../utils/helper";
import { ContentLoadingLoader, SampleToast } from "../../components";
import awsConfig from "../../constant/aws-exports";
import { RepaymentDetail } from "..";

const tableContainer = {
  marginTop: "20px",
  width: "100%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
  height: "auto",
  minWidth: "900px",
  overflowY: "scroll",
  marginLeft: "1%",
};

const tableHeader = (e) => {
  return {
    backgroundColor: "#ececec",
    fontWeight: "600",
    fontSize: 16,
    fontFamily: "Asap",
    color: "#1f1f1f",
    borderRight: "1px solid #c7c7c7",
    paddingTop: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "15px",
    width: e ? "200%" : "inherit",
  };
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingBottom: "10px",
};

const UpcomingRepayment = observer(function UpcomingRepayments(props) {
  const navigate = useHistory();
  const [filterData, setFilterData] = useState([]);
  const [tranche, setTranche] = useState("");
  const [dropDownData, setDropDownData] = useState([]);
  const [upcomingRepaymenyData, setUpcomingRepaymentData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 10;
  const [render, setRender] = useState(true);
  const rootStore = useStores();
  const [isLogin, setIsLogin] = useState();
  const [pan_number, setPanNumber] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [loginRender, setLoginRender] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [data, setData] = useState();

  // check if user login or not
  useEffect(() => {
    let checkLogin = async () => {
      try {
        const pan_number = props?.value?.pan_number || null;
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        setPanNumber(pan_number);
        fetchUpcomingRepayment(pan_number, tranche, page, rowsPerPage);
      } catch (err) {
        console.log("upcoming repayment use effect catch", err);
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, [props]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleOpen = (row) => {
    console.log("row");
    console.log(row);
    setData(row);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  // fetch the client list
  const fetchUpcomingRepayment = (
    pan_number = null,
    series_name = null,
    page = null,
    pagesize = null,
    isDownload = null
  ) => {
    rootStore.repayment
      .getUpcomingRepayment(pan_number, series_name, page, pagesize, isDownload)
      .then((res) => {
        if (res.kind === "ok") {
          if (isDownload == true) {
            const excluded_fields = [
              "investmentTransactionId",
              "investmentUUID",
              "mode_of_investment",
              "repayment_id",
              "repaymentTransactionId",
              "repaymentUUID",
              "status",
              "type",
              "bank_id",
              "client_id",
              "investmentId",
              "ncd_id",
              "repaymentId",
            ];
            const data = convert_json_array_to_csv(res.data, excluded_fields);
            downloadFile({
              data: data,
              fileName: "UpcomingRepayment.csv",
              fileType: "text/csv",
            });
          } else {
            setUpcomingRepaymentData(rootStore.repayment.repayment);
            setFilterData(rootStore.repayment.repayment);
            setRender(true);
            setTotalCount(res.total);
            console.log(`Seeries:${JSON.stringify(res.series_names)}`);
            setDropDownData([
              ...new Set(
                rootStore?.repayment?.repayment?.map((v) => v.series_name)
              ),
            ]);
          }
        } else {
          setRender(true);
          errorToast(res.error);
        }
      });
  };

  // request search function
  const requestSearch = (event) => {
    setPage(0);
    setPanNumber(event.target.value.toUpperCase());
    fetchUpcomingRepayment(
      event.target.value.toLowerCase(),
      tranche,
      page,
      rowsPerPage,
      isDownload
    );
  };

  const handleDownloadExcel = (event) => {
    setPage(0);
    fetchUpcomingRepayment(pan_number, tranche, page, rowsPerPage, true);
  };

  const requestDropDown = (event) => {
    setPage(0);
    setTranche(event.target.value);
    fetchUpcomingRepayment(
      pan_number,
      event.target.value,
      page,
      rowsPerPage,
      isDownload
    );
  };
  // handle page change function
  const handleChangePage = (event, newPage) => {
    fetchUpcomingRepayment(
      pan_number,
      tranche,
      newPage,
      rowsPerPage,
      isDownload
    );
    setPage(newPage);
  };

  // sort list via name
  const sortByName = (a, b) => {
    if (String(a.name).trim() < String(b.name).trim()) {
      return -1;
    }
    if (String(a.name).trim() > String(b.name).trim()) {
      return 1;
    }
    return 0;
  };

  const total = (row) => {
    // return (((row.repayment_tds_rate/100) * row.interest)-100 + row.principal);
    return (
      ((100 - row.repayment_tds_rate) / 100) * row.interest + row.principal
    );
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="repayment_div_container">
          {/* table */}
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="repayment_div_tablecontainer">
                {/* header */}
                <div className="repayment_header_div">
                  {/* text */}
                  <h1 className="repayment_header_div_title">
                    Upcoming Repayment
                  </h1>
                </div>

                {/* search_bar */}
                <div className="repayment_search_bar_column">
                  <div className="repayment_search_bar_div">
                    <div className="repayment_search_bar">
                      {/* icon */}
                      <SearchIcon
                        style={{
                          fontSize: "20px",
                          paddingRight: "10px",
                          color: "#555555",
                        }}
                      />
                      {/* input box */}
                      <input
                        type="text"
                        placeholder="Search by PAN number"
                        className="repayment_search_bar_input"
                        value={pan_number}
                        onChange={requestSearch}
                      />
                    </div>
                    {dropDownData && dropDownData.length > 0 ? (
                      <>
                        <div className="repayment_dropdown">
                          <FormControl
                            fullWidth
                            sx={{ minWidth: 120, width:'200px' }}
                            size="small"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Tranche and Series
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tranche}
                              label="Tranche and Series"
                              onChange={requestDropDown}
                            >
                              <MenuItem value="" key="-1">
                                Select Series
                              </MenuItem>
                              {dropDownData &&
                                dropDownData.length > 0 &&
                                dropDownData.map((name, index) => (
                                  <MenuItem value={name} key={index}>
                                    {name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="repayment_download">
                    <Button variant="contained" onClick={handleDownloadExcel} sx={{width:'200px'}}>
                      Download Excel
                    </Button>
                  </div>
                </div>

                {/* table */}
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell align="center" style={tableHeader}>
                          Repayment ID
                        </TableCell> */}
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Due Date
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          PAN Number
                        </TableCell>

                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Contact No.
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Email ID
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Series Name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          No of NCD
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Investment Start
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Principal
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Interest
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          TDS
                        </TableCell>
                        <TableCell
                          align="center"
                          style={tableHeader(upcomingRepaymenyData[0]?.name)}
                        >
                          Total Payout
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...tableHeader(upcomingRepaymenyData[0]?.name),
                            borderRight: "none",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {upcomingRepaymenyData
                        .slice()
                        .sort(sortByName)
                        // .slice(
                        //   page * rowsPerPage,
                        //   page * rowsPerPage + rowsPerPage
                        // )
                        .map((row, index) => (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#fff",
                            }}
                          >
                            {/* <TableCell style={tableRow} align="center">
                            <div style={{width: "220px"}}>{row.repaymentId}</div>
                          </TableCell> */}
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                <p
                                  className="repayment_client_name"
                                  onClick={() =>
                                    navigate.push("/profile", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      pan_number: row.pan_number,
                                    })
                                  }
                                >
                                  {row.name}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.due_date}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>
                                {row.pan_number}
                              </div>
                            </TableCell>

                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "200px" }}>{row.phone}</div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>{row.email}</div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>
                                {row.series_name}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>
                                {row.total_number_of_ncd}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>
                                {row.investment_start}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>
                                {row.principal}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>
                                {row.interest}
                              </div>
                            </TableCell>
                            <TableCell style={tableRow} align="center">
                              <div style={{ width: "220px" }}>
                                {row.repayment_tds_rate}
                              </div>
                            </TableCell>
                            <TableCell style={{ ...tableRow }} align="center">
                              <div style={{ width: "220px" }}>
                                {total(row).toFixed(2)}
                                {/* {row.principal + (100- (row.repayment_tds_rate/100*row.interest))} */}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ ...tableRow, borderRight: "none" }}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "300px",
                                  justifyContent: "space-around",
                                }}
                              >
                                <p
                                  className="repayment_client_name"
                                  onClick={() => handleOpen(row)}
                                >
                                  View Repayment
                                </p>
                                <p
                                  className="investor_client_name"
                                  onClick={() =>
                                    navigate.push("/investmentHistory", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      pan_number: row.pan_number,
                                    })
                                  }
                                >
                                  Investment History
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {upcomingRepaymenyData.length === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">No row found</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>
                {openDialog && (
                  <RepaymentDetail
                    data={data}
                    type="upcoming"
                    openDialog={openDialog}
                    setClose={handleClose}
                  />
                )}
                {/* pagination */}
                <div className="repayment_pagination_div" style={{}}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            )}
          </div>
          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
});

export default UpcomingRepayment;
