import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";
import {
  GetPoolByIdModel,
  GetPoolByIdSnapshot,
} from "../get-pool-by-id/get-pool-by-id";
import { Pools_API } from "../../../services/api";

// make the instance of api
const api = new Pools_API();

export const GetPoolByIdStoreModel = types
  .model("GetPoolByIdStore")
  .props({
    getpoolbyid: types.optional(types.array(GetPoolByIdModel), []),
  })
  .views((self) => ({}))
  .actions((self) => ({
    saveGetPoolByIds: (getpoolbyidSnapshot: GetPoolByIdSnapshot[]) => {
      self.getpoolbyid.push(getpoolbyidSnapshot);
    },
  }))
  .actions((self) => ({
    fetchGetPoolByIds: flow(function* (id: any) {
      const result = yield api.getPoolById(id);
      if (result.kind === "ok") {
        self.getpoolbyid.clear();
        self.saveGetPoolByIds(result.getpoolbyid);
        return { kind: "ok" };
      } else {
        return { kind: "bad-data", error: result.error };
      }
    }),
  }));

type GetPoolByIdStoreType = Instance<typeof GetPoolByIdStoreModel>;
export interface GetPoolByIdStore extends GetPoolByIdStoreType {}

type GetPoolByIdStoreSnapshotType = SnapshotOut<typeof GetPoolByIdStoreModel>;
export interface GetPoolByIdStoreSnapshot
  extends GetPoolByIdStoreSnapshotType {}
