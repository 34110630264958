import React, { useEffect, useState } from "react";
import "./pagenotfound.css";
import { Auth } from "aws-amplify";
import { Redirect } from "react-router-dom";

const Pagenotfound = (props) => {
  const [isLogin, setIsLogin] = useState();
  const [loginRender, setLoginRender] = useState(false);

  // check if user login or not
  useEffect(() => {
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLogin(true);
        setLoginRender(true);
        props.showCommonHeader(true);
        props.showCommonSidebar(true);
      } catch (err) {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="pagenotfound_container">
          <div className="pagenotfound_rest_container">
            <div>
              <p className="pagenotfound_container_title">404</p>
              <p className="pagenotfound_container_subtitle">
                Sorry, the page you are looking for is not available.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagenotfound;
