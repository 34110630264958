import { API } from "aws-amplify";
import { OverviewSnapshot } from "../../../models";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class Overview_API {
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  dataConverter = (raw: any) => {
    return {
      uuid: raw.uuid,
      pan_number: raw.pan_number === undefined ? "" : raw.pan_number,
      name: raw.name === undefined ? "" : raw.name,
      phone: raw.phone === undefined ? "" : raw.phone,
      email: raw.email === undefined ? "" : raw.email,
      address: raw.address === undefined ? "" : raw.address,
      referral_fee: raw.referral_fee,
      total_commission_accrued: raw.total_commission_accrued,
      total_clients: raw.total_clients,
    };
  };

  /**
   * @description This function is to get client list of ifa
   * @returns it returns user overall client list data.
   */
  async getOverview(): Promise<Types.GetOverviewResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/partner/overview", {
        queryStringParameters: {},
      });

      console.log(response)
      const data = response.data || [];
      //const data = JSON.parse(rawData?.data || []);

      const convertedData: OverviewSnapshot =this.dataConverter(data);

      return { kind: "ok", data: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return { kind: "bad-data", error: "Something went wrong" };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
}
